import * as React from "react";

function SvgScrollButtonArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 39 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.813 8.874H37.43m0 0l-9.38-7.868m9.38 7.868l-9.38 8.171"
                stroke="#FF8B38"
            />
        </svg>
    );
}

export default SvgScrollButtonArrow;
