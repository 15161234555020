import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../../utils/px-to-rem";

interface IPlanetAndStuff {
}

export const PlanetAndStuff = ({}: IPlanetAndStuff) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap.timeline({});
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return <div ref={mainRef} css={{
        position: "relative",
        paddingTop: pxToRem(100),
        paddingBottom: pxToRem(100)
    }}>
        <img css={{ width: "100%" }} src="/images/ff-home-jamun-with-rings.png" />
        <img css={{ width: "24%", position: "absolute", top: "22%", right:"14%", zIndex: -1 }} src="/images/ff-home-kalajam.png" />
        <img css={{ width: "20%", position: "absolute", bottom: "17%", left: "10%" }} src="/images/ff-home-ledikeni.png" />
        <img css={{ width: "16%", position: "absolute", bottom: "18%", right: "28%" }} src="/images/ff-home-small-spiral.png" />
        <img css={{ width: "30%", position: "absolute", top: "12%", left:"30%" }} src="/images/ff-home-white-thing.png" />
    </div>;
};

