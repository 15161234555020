import { IFfMapItem } from "./FfMapItem";
import * as CSS from "csstype";

export const FF_MAP_ITEMS: (IFfMapItem & {
    styles?: CSS.Properties;
})[] = [
    {
        imageUrl: "/images/ff-map-gulab-jaanu.png",
        text: "Maldives",
        width: "14.6vw",
        align: "left",
        isTextOnTop: false,
        id: "maldives",
        styles: {
            top: "55%",
            left: "17vw"
        }
    },
    {
        imageUrl: "/images/ff-map-gulab-jam.png",
        text: "Myanmar",
        width: "12.5vw",
        align: "left",
        isTextOnTop: true,
        id: "myanmar",
        styles: {
            top: "37.5%",
            right: "10vw"
        }
    },
    {
        imageUrl: "/images/ff-map-gulab-jamun.png",
        text: "Pakistan",
        width: "11.9vw",
        align: "left",
        isTextOnTop: false,
        id: "pakistan",
        styles: {
            top: "5%",
            left: "22vw"
        }
    },
    {
        imageUrl: "/images/ff-map-gulab-jamun-pink.png",
        text: "India",
        width: "19.7vw",
        align: "right",
        isTextOnTop: false,
        id: "india",
        styles: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }
    },
    {
        imageUrl: "/images/ff-map-gulab-jamun-right.png",
        text: "Nepal",
        width: "11.9vw",
        align: "right",
        isTextOnTop: false,
        id: "nepal",
        styles: {
            top: "10%",
            right: "27vw"
        }
    },
    {
        imageUrl: "/images/ff-map-rasgulla-green.png",
        text: "Fiji",
        width: "15.5vw",
        align: "right",
        isTextOnTop: false,
        id: "fiji",
        styles: {
            bottom: "20%",
            right: "3vw"
        }
    },
    {
        imageUrl: "/images/ff-map-rasgulla-white.png",
        text: "Mauritius",
        width: "16.7vw",
        align: "left",
        isTextOnTop: false,
        id: "mauritius",
        styles: {
            bottom: "2%",
            left: "3vw"
        }
    }
];
