import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { useWhyDidYouUpdate } from "ahooks";
import { pxToRem } from "../../utils/px-to-rem";
import { Text } from "../Text";
import {
    ScrollButtonArrow,
    ScrollButtonCircle,
    ScrollButtonCircleWhite
} from "../../assets/misc-svgs/components";

interface IJvScrollButton {
    onClick?: () => void;
    state: "scroll" | "trivia";
}

export const JvScrollButton = React.memo(
    ({ onClick, state }: IJvScrollButton) => {
        const mainRef = useRef(null);

        let mainTl;

        const buttonSwitchTl = useRef<GSAPTimeline>(null);
        const hoverTl = useRef<GSAPTimeline>(null);
        useLayoutEffect(() => {
            gsap.registerPlugin(TextPlugin);
            const ctx = gsap.context(() => {
                mainTl = gsap
                    .timeline({
                        repeat: -1
                    })
                    .to(".bg", {
                        rotate: 360,
                        ease: "none",
                        duration: 20
                    });

                buttonSwitchTl.current = gsap
                    .timeline({ paused: true, defaults: { duration: 1 } })
                    .fromTo(
                        ".next-button-text",
                        {
                            text: "Scroll"
                        },
                        {
                            text: "Go to trivia",
                            color: "white"
                        }
                    )

                    .to(
                        "#scroll-button-initial-arrow",
                        {
                            autoAlpha: 0,
                            duration: 0.5
                        },
                        "="
                    )
                    .to(
                        "#scroll-button-arrow",
                        {
                            autoAlpha: 1
                        },
                        "="
                    )
                    .to(
                        ".bg-orange",
                        {
                            autoAlpha: 0
                        },
                        "="
                    )
                    .to(
                        ".bg-white",
                        {
                            autoAlpha: 1
                        },
                        "="
                    )
                    .to(
                        "svg path",
                        {
                            stroke: "white"
                        },
                        "="
                    );
                hoverTl.current = gsap
                    .timeline({ paused: true, defaults: { duration: 0.5 } })
                    .to(
                        ".bg-white",
                        {
                            autoAlpha: 0,
                            duration: 0.25
                        },
                        "="
                    )
                    .to(
                        ".active-state",
                        {
                            autoAlpha: 1
                        },
                        "="
                    );
            }, mainRef);

            return () => ctx.revert();
        }, []);

        useEffect(() => {
            if (state === "trivia") {
                buttonSwitchTl.current.play();
            } else {
                buttonSwitchTl.current.reverse();
            }
        }, [state]);

        const onMouseEnter = useCallback(() => {
            if (state === "scroll") return;
            if (hoverTl.current) hoverTl.current.timeScale(1).play();
        }, [state]);

        const onMouseLeave = useCallback(() => {
            if (state === "scroll") return;
            if (hoverTl.current) hoverTl.current.timeScale(1).reverse();
        }, [state]);

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
                onClick={() => {
                    if (onClick) onClick();
                }}
                ref={mainRef}
                css={{
                    cursor: "pointer",
                    position: "relative",
                    display: "grid",
                    gridAutoFlow: "column",
                    gridGap: pxToRem(16),
                    gridTemplateColumns: `1fr ${pxToRem(70)}`,
                    height: pxToRem(70)
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <Text
                    className="next-button-text"
                    variant="nextButton"
                    css={{
                        color: "#FF8B38",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                        minWidth: pxToRem(200)
                    }}
                >
                    Scroll
                </Text>
                <div css={{ position: "relative", height: "100%" }}>
                    <div
                        css={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <ScrollButtonArrow
                            id="scroll-button-arrow"
                            css={{
                                opacity: 0,
                                width: "35%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                zIndex: 5,
                                transform: "translate(-50%, -50%)",
                                // right: "-20%",
                                filter: `drop-shadow(0px 0px ${pxToRem(
                                    4
                                )} #FF9B54)`,
                                padding: "10px",
                                overflow: "visible"
                                // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                            }}
                        />
                        <img
                            id="scroll-button-initial-arrow"
                            css={{
                                width: "20%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                zIndex: 5,
                                transform: "translate(-50%, -50%)",
                                // right: "-20%",
                                filter: `drop-shadow(0px 0px ${pxToRem(
                                    4
                                )} #FF9B54)`,
                                padding: "10px",
                                overflow: "visible"
                                // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                            }}
                            src="/images/drag-arrow.png"
                            alt=""
                        />

                        <div
                            className="active-state"
                            css={{
                                opacity: 0,
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                img: {
                                    width: "100%",
                                    transform: "scale(1.4)",
                                    zIndex: -1
                                }
                            }}
                        >
                            <img
                                alt=""
                                className="planet-hotspot-active"
                                src="/images/planet-hotspot-active.png"
                            />
                        </div>

                        <ScrollButtonCircle
                            className="bg bg-orange"
                            css={{
                                width: "100%",
                                filter: `drop-shadow(0px 0px ${pxToRem(
                                    1
                                )} #FF9B54)`,
                                overflow: "visible",

                                // path: {
                                //     strokeWidth: px
                                path: {
                                    // strokeWidth: pxToRem(2)
                                }
                                // }
                            }}
                        />
                        <ScrollButtonCircleWhite
                            className="bg bg-white"
                            css={{
                                opacity: 0,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0
                                // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
);
