import * as React from "react";

function SvgEncNavPath(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 807 807"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.5 403.5a403 403 0 10806 0 403 403 0 10-806 0"
                stroke="#fff"
            />
        </svg>
    );
}

export default SvgEncNavPath;
