exports.components = {
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-fun-facts-fact-1-puzzle-tsx": () => import("./../../../src/pages/fun-facts/fact-1-puzzle.tsx" /* webpackChunkName: "component---src-pages-fun-facts-fact-1-puzzle-tsx" */),
  "component---src-pages-fun-facts-fact-1-trivia-tsx": () => import("./../../../src/pages/fun-facts/fact-1-trivia.tsx" /* webpackChunkName: "component---src-pages-fun-facts-fact-1-trivia-tsx" */),
  "component---src-pages-fun-facts-fact-2-puzzle-tsx": () => import("./../../../src/pages/fun-facts/fact-2-puzzle.tsx" /* webpackChunkName: "component---src-pages-fun-facts-fact-2-puzzle-tsx" */),
  "component---src-pages-fun-facts-fact-2-trivia-tsx": () => import("./../../../src/pages/fun-facts/fact-2-trivia.tsx" /* webpackChunkName: "component---src-pages-fun-facts-fact-2-trivia-tsx" */),
  "component---src-pages-fun-facts-fact-3-puzzle-tsx": () => import("./../../../src/pages/fun-facts/fact-3-puzzle.tsx" /* webpackChunkName: "component---src-pages-fun-facts-fact-3-puzzle-tsx" */),
  "component---src-pages-fun-facts-fact-3-trivia-tsx": () => import("./../../../src/pages/fun-facts/fact-3-trivia.tsx" /* webpackChunkName: "component---src-pages-fun-facts-fact-3-trivia-tsx" */),
  "component---src-pages-fun-facts-index-tsx": () => import("./../../../src/pages/fun-facts/index.tsx" /* webpackChunkName: "component---src-pages-fun-facts-index-tsx" */),
  "component---src-pages-index-copy-tsx": () => import("./../../../src/pages/index copy.tsx" /* webpackChunkName: "component---src-pages-index-copy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jamunverse-tsx": () => import("./../../../src/pages/jamunverse.tsx" /* webpackChunkName: "component---src-pages-jamunverse-tsx" */),
  "component---src-pages-katori-tsx": () => import("./../../../src/pages/katori.tsx" /* webpackChunkName: "component---src-pages-katori-tsx" */)
}

