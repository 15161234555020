import React, { useState } from "react";

import type { HeadFC } from "gatsby";

import loadable from "@loadable/component";
import AllPlanetsWithJamunverseSSR from "../components/Jamunverse/AllPlanetsWithJamunverse";
import { SEO } from "../components/Seo";

const AllPlanetsWithJamunverse = loadable(
    () => import("../components/Jamunverse/AllPlanetsWithJamunverse")
);

const JamunVersePage = () => {
    return (
        <main>
            <div id="jamunverse-main-portal" />
            <div id="jamunverse-gulabjamun-portal" />
            <div id="jamunverse-rasgulla-portal" />
            <div id="jamunverse-kalajam-portal" />
            <div id="jamunverse-ledikeni-portal" />
            <div id="jamunverse-jhurra-portal" />
            <div id="jamunverse-pantua-portal" />
            <AllPlanetsWithJamunverseSSR />
        </main>
    );
};

export default JamunVersePage;

export const Head: HeadFC = () => <SEO title="JamunVerse" />;
