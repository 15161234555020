import React from "react";

import type { HeadFC } from "gatsby";
import { navigate } from "gatsby";
import { FunFactsHome } from "../../components/FunFactsHome";

import { SEO } from "../../components/Seo";

export const Head: HeadFC = () => <SEO title="Jamun Trivia" />;

const FunFactsIndexPage = ({children, transitionStatus, entry, exit}) => {
    return (
        <main>
            <FunFactsHome
                onPlayButtonClick={() => navigate("/fun-facts/fact-1-puzzle")}
            />
        </main>
    );
};

export default FunFactsIndexPage;

