import React from "react";
import { useSiteMetadata } from "../../utils/use-site-metadata";

export const SEO = ({ title, description, pathname, children } : {
    title?: string;
    description?: string;
    pathname?: string;
    children?: React.ReactNode;
}) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        image,
        siteUrl,
        twitterUsername,
        domain
    } = useSiteMetadata();

    const seo = {
        title: title ? `${title} | ${defaultTitle}` : defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
        domain
    };

    return (
        <>
            <title>{seo.title}</title>

            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content={seo.domain} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:url" content={seo.url} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
            <meta name="twitter:creator" content={seo.twitterUsername} />
            <link
                rel="icon"
                href="/favicon-32x32.png"
            />
            {children}
        </>
    );
};
