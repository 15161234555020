import React from "react";
import { EncyclopediaNav, INavItem } from "../EncyclopediaNav";
import { IAnyPlanet } from "./types";
function reverseString(str) {
    return str.split("").reverse().join("");
}

const REVERSE_JHURRA_TEXT = (
    <>
        {reverseString("rasgulla")}&nbsp;{reverseString("ka")}&nbsp;
        {reverseString("jhurre")}
    </>
);
const JHURRA_TEXT = <>Jhurre&nbsp;Ka&nbsp;Rasgulla</>;

export const NAV_ITEMS: INavItem[] = [
    {
        name: "jhurra",
        imageUrl: "/images/nav-jhurra.png",
        text: JHURRA_TEXT,
        reverseText: REVERSE_JHURRA_TEXT,
        direction: "anticlockwise"
    },
    {
        name: "rasgulla",
        imageUrl: "/images/nav-rasgulla.png",
        text: "Rasgulla",
        reverseText: reverseString("rasgulla"),
        direction: "anticlockwise"
    },
    {
        name: "kalajam",
        imageUrl: "/images/nav-kalajam.png",
        text: <>Kala&nbsp;&nbsp;Jam</>,
        reverseText: (
            <>
                {reverseString("jam")}&nbsp;&nbsp;{reverseString("kala")}
            </>
        ),
        direction: "clockwise"
    },
    {
        name: "gulabjamun",
        imageUrl: "/images/nav-jhurra.png",
        text: <>Gulab&nbsp;Jamun</>,
        reverseText: (
            <>
                {reverseString("jamun")}&nbsp;{reverseString("gulab")}
            </>
        ),
        direction: "anticlockwise"
    },
    {
        name: "pantua",
        imageUrl: "/images/nav-pantua.png",
        text: "Pantua",
        reverseText: reverseString("pantua"),
        direction: "clockwise"
    },
    {
        name: "ledikeni",
        imageUrl: "/images/nav-ledikeni.png",
        reverseText: <>{reverseString("ledikeni")}</>,
        text: <>ledikeni</>,
        direction: "clockwise"
    }
];
export const Nav = React.memo(
    ({ setOpenPlanet }: { setOpenPlanet: (a: IAnyPlanet) => void }) => {
        const navItems = {
            item1: NAV_ITEMS[0],
            item1OnClick: () => setOpenPlanet("jhurra"),
            item2: NAV_ITEMS[1],
            item2OnClick: () => setOpenPlanet("rasgulla-sun"),
            item3: NAV_ITEMS[2],
            item3OnClick: () => setOpenPlanet("kalajam"),
            item4: NAV_ITEMS[3],
            item4OnClick: () => setOpenPlanet("gulabjamun"),
            item5: NAV_ITEMS[4],
            item5OnClick: () => setOpenPlanet("pantua"),
            item6: NAV_ITEMS[5],
            item6OnClick: () => setOpenPlanet("ledikeni")
        };
        return <EncyclopediaNav {...navItems} />;
    }
);
