import React, { useState } from "react";

import type { HeadFC } from "gatsby";
import { WithNextButton } from "../../components/WithNextButton";
import { FITB } from "../../components/FITB";

import { SEO } from "../../components/Seo";

export const Head: HeadFC = () => <SEO title="Trivia 3" />;

const FunFact3PuzzlePage = () => {
    const [showButton, setShowButton] = useState(false);
    return (
        <main>
            <WithNextButton
                to="/fun-facts/fact-3-trivia"
                type="default"
                showButton={showButton}
            >
                <FITB onComplete={() => setShowButton(true)} />
            </WithNextButton>
        </main>
    );
};

export default FunFact3PuzzlePage;

