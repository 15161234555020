import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../../utils/px-to-rem";
import { Text } from "../../Text";

export type IFfMapCountries =
    | "maldives"
    | "myanmar"
    | "pakistan"
    | "india"
    | "fiji"
    | "nepal"
    | "mauritius";

export interface IFfMapItem {
    width: string;
    isTextOnTop?: boolean;
    align?: "left" | "right";
    imageUrl: string;
    text: string;
    id: IFfMapCountries;
}

export const FfMapItem = ({
    width,
    text,
    isTextOnTop = false,
    imageUrl,
    align = "left",
    id
}: IFfMapItem) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap.timeline({});
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div ref={mainRef} id={id}>
            <div
                css={{
                    display: "grid",
                    gridGap: pxToRem(2),
                    width: width,
                    justifyItems: align === "left" ? "start" : "end"
                }}
            >
                <img
                    className="map-item-image"
                    css={{
                        width: "100%",
                        gridRow: isTextOnTop ? "2 / span 1" : "1 / span 1"
                    }}
                    src={imageUrl}
                />
                <Text
                    css={{
                        gridRow: isTextOnTop ? "1 / span 1" : "2 / span 1"
                    }}
                    className="map-item-text"
                    variant="mapItemText"
                    color="rgba(161, 161, 168, 0.95)"
                >
                    {text}
                </Text>
            </div>
        </div>
    );
};
