import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";

export interface IPlanetHotspot {
    onClick?: () => void;
    initialScale?: number;
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
}

export const PlanetHotspot = ({
    onClick: onClickProp,
    initialScale = 1
}: IPlanetHotspot) => {
    const containerRef = useRef(null);
    const OUTER_WIDTH = 40 * initialScale;
    const WIDTH = 30 * initialScale;
    const CIRCLEWIDTHFACTOR = 0.6;

    const inactiveTl = useRef<GSAPTimeline>(null);
    const onClickTl = useRef<GSAPTimeline>(null);
    useEffect(() => {
        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
        const ctx = gsap.context(() => {
            inactiveTl.current = gsap
                .timeline({})
                .to(".planet-hotspot-ring", {
                    rotate: "360deg",
                    duration: 40,
                    ease: "none",
                    repeat: -1
                })
                .to(
                    ".circle",
                    {
                        scale: 0.2,
                        duration: 3,
                        yoyo: true,
                        repeat: -1,
                        ease: "none"
                    },
                    "="
                );

            onClickTl.current = gsap
                .timeline({ paused: true })
                .to(".inactive-state", {
                    autoAlpha: 0,
                    duration: 0.3
                })
                .to(
                    ".active-state",
                    {
                        autoAlpha: 1,
                        duration: 0.4
                    },
                    "="
                );
            return () => ctx.revert(); // cleanup
        }, containerRef); // <- IMPORTANT! Scopes selector text
    }, []);

    const onClick = () => {
        if (onClickProp) {
            onClickProp();
        }
    };

    const onMouseEnter = () => {
        inactiveTl.current && inactiveTl.current.pause();
        onClickTl.current && onClickTl.current.timeScale(1).play();
    };

    const onMouseLeave = () => {
        inactiveTl.current && inactiveTl.current.play();
        onClickTl.current && onClickTl.current.timeScale(2).reverse();
    };
    return (
        <div
            css={{
                position: "relative",
                zIndex: 11,
                // top,
                // left,
                // right,
                // bottom,
                "*": {
                    transformOrigin: "center center"
                },
                ".inactive-state": {
                    cursor: "pointer",
                    ".planet-hotspot-ring": {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                    },
                    position: "absolute",
                    width: pxToRem(WIDTH),
                    height: pxToRem(WIDTH),
                    top: pxToRem((OUTER_WIDTH - WIDTH) / 2),
                    left: pxToRem((OUTER_WIDTH - WIDTH) / 2)
                },
                width: pxToRem(OUTER_WIDTH),
                height: pxToRem(OUTER_WIDTH),
                img: {
                    width: "100%"
                },
                ".circle": {
                    position: "absolute",
                    top: pxToRem(((1 - CIRCLEWIDTHFACTOR) * WIDTH) / 2),
                    left: pxToRem(((1 - CIRCLEWIDTHFACTOR) * WIDTH) / 2),
                    width: pxToRem(CIRCLEWIDTHFACTOR * WIDTH),
                    height: pxToRem(CIRCLEWIDTHFACTOR * WIDTH),
                    borderRadius: "50%",
                    background: "#fff"
                },
                ".active-state": {
                    cursor: "pointer",
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    zIndex: -1,
                    left: 0,
                    right: 0,
                    bottom: 0
                }
            }}
            className="hotspot"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            ref={containerRef}
        >
            <div role="button" className="inactive-state">
                <img
                    className="planet-hotspot-ring"
                    src="/images/planet-hotspot-ring.png"
                />
                <div className="circle"></div>
            </div>
            <div className="active-state">
                <img
                    className="planet-hotspot-active"
                    src="/images/planet-hotspot-active.png"
                />
            </div>
        </div>
    );
};
