import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

interface IBasicLoader {}

export const BasicLoader = ({}: IBasicLoader) => {
    const mainRef = useRef(null);

    const [isMounted, setIsMounted] = React.useState(false);

    useLayoutEffect(() => {
        const fadeOutLoader = () => {
            gsap.to(mainRef.current, {
                duration: 0.75,
                autoAlpha: 0,
                // clipPath: 'inset(0% 0% 100% 0%)',
                ease: "power3.out"
            }).then(() => {
                setIsMounted(true);
            });
        };
        if (document.readyState === "complete") {
            fadeOutLoader();
        } else {
            window.addEventListener("load", fadeOutLoader);
        }

        return () => {
            window.removeEventListener("load", fadeOutLoader);
        };
    }, []);

    return (
        <div
            ref={mainRef}
            css={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000,
                backgroundColor: "black",
                display: isMounted ? "none" : "block"
            }}
        />
    );
};
