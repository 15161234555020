import styled from "@emotion/styled";
import {breakpoints} from "../../utils/breakpoints";

export const StyledComingSoonPage = styled.div(({}) => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    ".gj-main-bg-container": {
        zIndex: 0,
        position: "absolute",
        top: "-100vh",
        left: "-100vw",
        right: "0",
        bottom: "0",
        width: "300vw",
        height: "300vh",
        ".gj-main-bg": {
            width: "100%",
            height: "100%",
            backgroundRepeat: "repeat",
            backgroundSize: "160vw 160vh"
        }
    },
    ".content": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
    },
    ".gj-gulab-jamun-sphere-container": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ".gj-gulab-jamun-sphere-and-glow": {
            width: "400px",
            maxWidth: "70vw",
            position: "relative",
            marginTop: "-7%",
            ".gj-gulab-jamun-sphere": {
                zIndex: 5,
                position: "absolute",
                top: "2%",
                left: 0,
                right: 0,
                bottom: 0,
                marginLeft: "20px",
            },
            ".gj-jeep-container": {
                zIndex: 12,
                position: "absolute",
                left: "-50%",
                width: "60%",
                height: "50%",
                top: "50%",
                ".gj-jeep": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",

                },
                ".gj-jeep-side": {
                    backgroundImage: "url(../../images/jeep.png)",
                },
            },

            ".gj-gulab-jamun-ring": {
                zIndex: 3,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                marginLeft: "-50%",
                width: "200%"
            },
            ".gj-gulab-jamun-sphere-glow": {
                zIndex: 4,
            }
        },
    },
    ".gj-dessert-roz-logo-container": {
        position: "absolute",
        top: "15px",
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        "svg": {
            width: "100% !important",
        },
        ".gj-dessert-roz-logo": {
            // TODO - make responsive
            width: "160px",
        },
        [`${breakpoints.medium}`]: {
            ".gj-dessert-roz-logo": {
                // TODO - make responsive
                width: "200px",
                maxWidth: "60vw"
            },
        }
    },
    ".gj-coming-soon-and-gulab-jamun-text": {
        // TODO - make responsive
        display: "grid",
        alignContent: "start",
        justifyItems: "center",
        gridGap: "25px",
        position: "absolute",
        bottom: "15vh",
        left: 0,
        right: 0,
        zIndex: 10,
        ".gj-gulab-jamun-text": {
            width: "90vw",
        },
        [`${breakpoints.medium}`]: {
            bottom: "10vh",
            ".gj-gulab-jamun-text": {
                width: "705px",
                maxWidth: "80vw",
            },
        },
        ".gj-coming-soon-text": {
            color: "#FFFFFF",
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "80px auto 80px",
            gridGap: "15px",
            ".gj-coming-soon-streak.right": {
                transform: "rotate(180deg) translateX(5px)",
                marginRight: "-4px"
            },
            [`${breakpoints.medium}`]: {
                gridTemplateColumns: "100px 1fr 100px",

            }
        },
    },
    ".gj-menu": {
        color: "#FFFFFF",
        // TODO - make responsive
        display: "grid",
        justifyItems: "start",
        position: "absolute",
        justifyContent: "space-between",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        gridAutoFlow: "column",
        padding: "2vmax 5vh",
        ".gj-menu-item:hover": {
            transform: "skew(-5deg)",
        },
        [`${breakpoints.medium}`]: {
            top: 0,
            right: "initial",
            gridAutoFlow: "row",
            alignContent: "space-between",

        }

    }

}));

export const StyledComingSoonPageLoader = styled.div(({}) => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "#000000"
}))
