import React from "react";
import WelcomeSection from "../WelcomeSection";

const WelcomePage = () => {
    return (
        // <SmoothScroll>

        <WelcomeSection imageWidth={1280} imageHeight={720} />
        // </SmoothScroll>
    );
};

export default WelcomePage;
