import React, { useLayoutEffect } from "react";
import * as THREE from "three";
import { JV_Z_INDICES } from "../Jamunverse/constants";
import testVertexShader from "./shaders/vertex.glsl";
import testFragmentShader from "./shaders/fragment.glsl";

export const NewShader = () => {
    let windowResizeCallback: () => void = () => {};
    let mouseMoveCallback: (e: MouseEvent) => void = () => {};
    const [mouseX, setMouseX] = React.useState(0);
    const [mouseY, setMouseY] = React.useState(0);
    useLayoutEffect(() => {
        // Canvas
        const canvas = document.querySelector("canvas.webgl");

        // Scene
        const scene = new THREE.Scene();

        /**
         * Sizes
         */

        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        /**
         * Camera
         */
        // Base camera
        const camera = new THREE.PerspectiveCamera(
            75,
            sizes.width / sizes.height,
            0.1,
            100
        );
        camera.position.set(0, 0, 1);
        scene.add(camera);

        /**
         * Renderer
         */
        const renderer = new THREE.WebGLRenderer({
            canvas,
            antialias: true,
            precision: "highp"
        });

        windowResizeCallback = () => {
            // Update sizes
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;

            // Update camera
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();

            // Update renderer
            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

            material.uniforms.iResolution.value = new THREE.Vector3(
                sizes.width,
                sizes.height,
                sizes.width / sizes.height
            );
        };

        mouseMoveCallback = (e) => {
            material.uniforms.iMouse.value = new THREE.Vector2(
                e.clientX,
                e.clientY
            );
        }

        window.addEventListener("mousemove", mouseMoveCallback);
        window.addEventListener("resize", windowResizeCallback);

        /**
         * Test mesh
         */
        // Geometry

        const geometry = new THREE.PlaneGeometry(4, 4, 32, 32);

        // Material
        const material = new THREE.ShaderMaterial({
            vertexShader: testVertexShader,
            fragmentShader: testFragmentShader,
            side: THREE.DoubleSide,
            uniforms: {
                iTime: { value: 0 },
                iMouse: { value: new THREE.Vector2(0, 0) },
                iResolution: {
                    value: new THREE.Vector3(
                        sizes.width,
                        sizes.height,
                        sizes.width / sizes.height
                    )
                }
            }
        });

        // Mesh
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        // Controls
        // const controls = new OrbitControls(camera, canvas)
        // controls.enableDamping = true

        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        console.log(material);

        /**
         * Animate
         */
        const clock = new THREE.Clock();

        const tick = () => {
            // Update controls
            // controls.update()

            const elapsedTime = clock.getElapsedTime();

            // Update material
            material.uniforms.iTime.value = elapsedTime;

            // Render
            renderer.render(scene, camera);

            // Call tick again on the next frame
            window.requestAnimationFrame(tick);
        };

        tick();

        return () => {
            window.removeEventListener("mousemove", mouseMoveCallback);
            window.removeEventListener("resize", windowResizeCallback);
        };
    }, []);
    return (
        <div
            css={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: JV_Z_INDICES.chaashni
            }}
        >
            <canvas className="webgl" />
        </div>
    );
};
