import * as React from "react";

function SvgFfConstellation(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 293 393"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#ff-constellation_svg__filter0_dd_110_834)">
                <path
                    d="M187.18 343.047c.064-.729 1.105-.792 1.257-.077a.637.637 0 001.142.236c.419-.589 1.341-.134 1.127.557l-.082.265c-.165.534.366 1.02.89.827.703-.259 1.274.638.709 1.13l-.267.232a.674.674 0 00.251 1.156l.336.099c.713.211.609 1.252-.131 1.319l-.235.021a.675.675 0 00-.46 1.101l.226.274c.469.567-.211 1.356-.842.978a.654.654 0 00-.983.668l.026.154c.118.712-.859 1.032-1.185.388a.63.63 0 00-1.155.07c-.245.679-1.254.48-1.223-.241l.006-.156a.654.654 0 00-1.056-.544c-.58.453-1.351-.248-.955-.868l.191-.299a.674.674 0 00-.59-1.037l-.235.007c-.744.024-.973-.997-.291-1.293l.321-.139a.674.674 0 00.109-1.178l-.293-.198c-.621-.419-.163-1.379.566-1.207.544.128 1.012-.42.783-.93l-.113-.252c-.296-.661.563-1.225 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <g filter="url(#ff-constellation_svg__filter1_dd_110_834)">
                <path
                    d="M44.008 204.757c.064-.729 1.105-.792 1.257-.077a.637.637 0 001.142.236c.42-.59 1.34-.134 1.127.557l-.082.265c-.165.534.366 1.02.89.827.703-.259 1.274.638.709 1.13l-.267.232a.675.675 0 00.251 1.156l.336.099c.713.211.61 1.252-.131 1.319l-.235.021a.674.674 0 00-.46 1.101l.226.274c.469.567-.211 1.356-.842.978a.654.654 0 00-.983.668l.026.154c.119.712-.859 1.032-1.184.388a.63.63 0 00-1.156.07c-.245.679-1.254.48-1.223-.241l.007-.156a.654.654 0 00-1.057-.544c-.58.453-1.35-.248-.955-.868l.191-.299a.674.674 0 00-.59-1.037l-.235.007c-.744.024-.973-.997-.29-1.293l.32-.139a.675.675 0 00.11-1.178l-.294-.198c-.62-.419-.163-1.379.566-1.207.544.128 1.012-.42.783-.93l-.113-.252c-.296-.661.564-1.225 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <g filter="url(#ff-constellation_svg__filter2_dd_110_834)">
                <path
                    d="M246.897 160.823c.064-.728 1.104-.792 1.257-.077a.637.637 0 001.141.237c.42-.59 1.341-.135 1.128.557l-.082.264c-.165.534.366 1.021.89.828.703-.26 1.274.638.708 1.129l-.266.232a.674.674 0 00.251 1.156l.335.1c.714.211.61 1.251-.131 1.318l-.234.021a.675.675 0 00-.46 1.101l.226.274c.469.568-.211 1.357-.842.978a.655.655 0 00-.983.668l.026.154c.118.712-.859 1.032-1.185.388a.63.63 0 00-1.156.071c-.245.679-1.254.48-1.222-.241l.006-.157a.654.654 0 00-1.056-.544c-.58.453-1.351-.248-.955-.868l.191-.299a.674.674 0 00-.59-1.037l-.236.007c-.743.024-.972-.996-.29-1.292l.321-.14a.674.674 0 00.109-1.177l-.293-.198c-.621-.42-.163-1.38.566-1.208.544.128 1.012-.42.783-.929l-.113-.253c-.296-.66.563-1.224 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <g filter="url(#ff-constellation_svg__filter3_dd_110_834)">
                <path
                    d="M116.927 40.62c.064-.727 1.104-.79 1.257-.076a.637.637 0 001.142.236c.419-.59 1.34-.134 1.127.557l-.082.265c-.165.534.366 1.02.89.828.703-.26 1.274.638.709 1.13l-.267.231a.674.674 0 00.251 1.156l.335.1c.714.21.61 1.251-.131 1.318l-.234.021a.675.675 0 00-.46 1.101l.226.274c.469.567-.211 1.357-.842.978a.655.655 0 00-.983.668l.026.154c.118.712-.859 1.032-1.185.388a.63.63 0 00-1.155.07c-.246.68-1.255.48-1.223-.24l.006-.157a.654.654 0 00-1.056-.544c-.58.453-1.351-.248-.955-.868l.191-.3a.674.674 0 00-.59-1.036l-.236.007c-.743.024-.972-.996-.29-1.293l.321-.139a.674.674 0 00.109-1.178l-.293-.197c-.621-.42-.163-1.38.566-1.208.544.128 1.012-.42.783-.93l-.113-.252c-.296-.66.563-1.224 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <path
                d="M44.922 209.396L117.84 45.26"
                stroke="url(#ff-constellation_svg__paint0_linear_110_834)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                d="M117.743 45.02L248 165"
                stroke="url(#ff-constellation_svg__paint1_linear_110_834)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                d="M187.874 348L45 209"
                stroke="url(#ff-constellation_svg__paint2_linear_110_834)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                d="M247.874 165L188 348"
                stroke="url(#ff-constellation_svg__paint3_linear_110_834)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <defs>
                <linearGradient
                    id="ff-constellation_svg__paint0_linear_110_834"
                    x1={74.875}
                    y1={141.2}
                    x2={44.922}
                    y2={209.396}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0} />
                    <stop offset={0.656} stopColor="#FF8B38" />
                </linearGradient>
                <linearGradient
                    id="ff-constellation_svg__paint1_linear_110_834"
                    x1={171.966}
                    y1={94.665}
                    x2={138.143}
                    y2={33.701}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0} />
                    <stop offset={0.656} stopColor="#FF8B38" />
                </linearGradient>
                <linearGradient
                    id="ff-constellation_svg__paint2_linear_110_834"
                    x1={128.385}
                    y1={290.5}
                    x2={170.396}
                    y2={358.757}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0} />
                    <stop offset={0.656} stopColor="#FF8B38" />
                </linearGradient>
                <linearGradient
                    id="ff-constellation_svg__paint3_linear_110_834"
                    x1={223.399}
                    y1={240.233}
                    x2={210.897}
                    y2={233.483}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0} />
                    <stop offset={0.656} stopColor="#FF8B38" />
                </linearGradient>
                <filter
                    id="ff-constellation_svg__filter0_dd_110_834"
                    x={143.244}
                    y={302.466}
                    width={89.781}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
                <filter
                    id="ff-constellation_svg__filter1_dd_110_834"
                    x={0.073}
                    y={164.176}
                    width={89.781}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
                <filter
                    id="ff-constellation_svg__filter2_dd_110_834"
                    x={202.961}
                    y={120.242}
                    width={89.782}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
                <filter
                    id="ff-constellation_svg__filter3_dd_110_834"
                    x={72.991}
                    y={0.04}
                    width={89.781}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default SvgFfConstellation;
