import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../../utils/px-to-rem";
import { PngAnimatedLetter } from "../../PngAnimatedLetter";
import { PlayButton } from "../PlayButton";
import { PlanetAndStuff } from "../PlanetAndStuff";
import TransitionLink from "gatsby-plugin-transition-link";
import { Hyperspace } from "../../Hyperspace";
import { useImagesLoaded } from "../../../utils/use-images-loaded";

interface IBackground {
    onPlayButtonClick?: () => void;
}

const jamunLetters = ["j", "a", "m", "u", "n"];
const triviaLetters = ["t", "r", "i", "v", "i", "a"];

export const JamunTriviaLetters = () => (
    <>
        <div
            css={{
                display: "grid",
                gridGap: pxToRem(8),
                justifyContent: "center",
                textAlign: "center",
                justifyItems: "center"
            }}
        >
            <div
                css={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridGap: pxToRem(8),
                    justifyContent: "start"
                }}
            >
                {jamunLetters.map((letter, index) => (
                    <PngAnimatedLetter
                        duration={1.5}
                        // delay={index * 0.1}
                        height={pxToRem(80)}
                        image1Src={`/images/jamun-trivia-letters/jamun/${letter}.png`}
                        image2Src={`/images/jamun-trivia-letters/jamun/${letter}-blue.png`}
                    />
                ))}
            </div>
            <div
                css={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridGap: pxToRem(8),
                    justifyContent: "start"
                }}
            >
                {triviaLetters.map((letter, index) => (
                    <PngAnimatedLetter
                        // delay={index * 0.1}
                        duration={1.5}
                        height={pxToRem(80)}
                        image1Src={`/images/jamun-trivia-letters/trivia/${letter}.png`}
                        image2Src={`/images/jamun-trivia-letters/trivia/${letter}-blue.png`}
                    />
                ))}
            </div>
        </div>
    </>
);

const EXIT_ANIM_DURATION = 3;
export const FFHomeBackground = ({ onPlayButtonClick }: IBackground) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap.timeline({});
            gsap.timeline({}).to(".gj-ff-stars", {
                rotate: "360deg",
                duration: 450,
                repeat: -1,
                ease: "none"
            });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    const { imagesLoaded } = useImagesLoaded();
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({ paused: true })
                .to(".non-hyperspace", { autoAlpha: 1 });

            if (imagesLoaded) {
                mainTl.play();

            }
        }, mainRef); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert();
    }, [imagesLoaded]);

    const exitAnimation = () => {
        gsap.timeline({
            defaults: {
                duration: EXIT_ANIM_DURATION * 0.75
            }
        })
            .to(".non-hyperspace", {
                autoAlpha: 0,
                ease: "power4.out"
            })
            .to(
                ".hyperspace",
                {
                    autoAlpha: 1
                },
                "<+=5%"
            )
            .to(".hyperspace", {
                autoAlpha: 0,
                duration: EXIT_ANIM_DURATION * 0.25,
                ease: "power3.out"
            });
    };

    return (
        <div
            ref={mainRef}
            css={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                overflow: "scroll"
            }}
        >
            <div
                className="hyperspace"
                css={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: -1,
                    opacity: 0
                }}
            >
                <Hyperspace count={3500} />
            </div>

            <div className="non-hyperspace" css={{ opacity: 0 }}>
                <div
                    css={{
                        height: "100vh",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100vw",
                        overflow: "hidden"
                    }}
                >
                    <img
                        className="gj-ff-stars"
                        css={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            transform: "scale(1.2)",
                            transformOrigin: "center center",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            objectFit: "cover"
                        }}
                        src="/images/ff-home-stars.png"
                    />
                </div>
                <div
                    css={{
                        display: "grid",
                        alignItems: "center",
                        maxWidth: "900px",
                        margin: "auto",
                        height: "100vh",
                        gridTemplateColumns: "auto 40vw"
                    }}
                >
                    <div
                        css={{
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center",
                            justifyItems: "center",
                            gridGap: pxToRem(64),
                            textAlign: "center"
                        }}
                    >
                        <JamunTriviaLetters />
                        <TransitionLink
                            exit={{
                                trigger: ({ exit, node }) => {
                                    exitAnimation();
                                },
                                length: EXIT_ANIM_DURATION
                            }}
                            entry={{
                                delay: EXIT_ANIM_DURATION
                            }}
                            to="/fun-facts/fact-1-puzzle"
                        >
                            {/*<Link to="/fun-facts/fact-1-puzzle">*/}
                            <PlayButton onClick={onPlayButtonClick} />
                        </TransitionLink>
                        {/*</Link>*/}
                    </div>
                    <PlanetAndStuff />
                </div>
            </div>
        </div>
    );
};
