export const JV_Z_INDICES = {
    chaashni: -4,
    jamunverse: 0,
    inactivePlanets: -2,
    hotspotsText: 0,
    overlay: 8,
    scroller: 10,
    hotspots: 12,
    activePlanet: 16
};

export const JV_SCALE = 1.3;
