import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { SplitText } from "gsap/SplitText";

export const enterTextViaScrambling = (
    selector,
    duration = 2,
    splitType = "words,lines,chars"
) => {
    gsap.registerPlugin(SplitText);

    const split = new SplitText(selector, {
        type: splitType,
        charsClass: "char",
        linesClass: "line",
        wordsClass: "word"
    });
    return gsap
        .timeline({ paused: true })
        .set(selector, {
            autoAlpha: 1
        })
        .fromTo(
            split.chars,
            {
                visibility: "hidden"
            },
            {
                visibility: "visible",
                stagger: {
                    each: duration / split.chars.length
                },
                ease: "none"
            }
        );
};

export const enterTextViaScramblingWithoutBreakingChars = (
    selector,
    duration = 2,
) => {
    gsap.registerPlugin(SplitText);

    const split = new SplitText(selector, {
        type: "lines",
        linesClass: "line",
    });
    return gsap
        .timeline({ paused: true })
        .set(selector, {
            autoAlpha: 1
        })
        .fromTo(
            split.lines,
            {
                clipPath: "inset(0% 100% 0% 0%)"
            },
            {
                clipPath: "inset(0% 0% 0% 0%)",
                stagger: {
                    each: duration / split.lines.length
                },
                ease: "none"
            }
        );
};

// export const enterTextViaScramblingControlled = (chars, duration = 2) => {
//     return gsap.fromTo(
//         chars,
//         {
//             visibility: "hidden"
//         },
//         {
//             paused: true,
//             visibility: "visible",
//             stagger: {
//                 each: duration / chars.length
//             },
//             ease: "none"
//         }
//     );
// };

export const fadeInText = (selector, duration = 0.5) => {
    gsap.registerPlugin(TextPlugin);
    return gsap.from(selector, {
        duration,
        autoAlpha: 0,
        paused: true
    });
};
