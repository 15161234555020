import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";
import FfFuelStationSVG from "./FfFuelStationSVG";
import { Text } from "../Text";
import { enterTextViaScrambling } from "../../utils/text-animations";
import { TextPlugin } from "gsap/TextPlugin";

const IMAGE_ASPECT_RATIO = 200/531;

interface IFfFuel {
    onComplete: () => void;
}

const DEFAULT_CAPTION_TEXT =
    <>Fill up your fuel tank for journey to the Gulab Jamun galaxy. <br /><br /> *hint: press the button</>;
const DEFAULT_FUEL = 0;

export const FfFuel = ({ onComplete }: IFfFuel) => {
    const mainRef = useRef(null);

    const [currentFuel, setCurrentFuel] = useState(DEFAULT_FUEL);

    const [isFuelComplete, setIsFuelComplete] = useState(false);

    const [fillFuelTl, setFillFuelTl] = useState(null);
    const [buttonClickInteraction, setButtonClickInteraction] = useState(null);

    const buttonHoverTl = useRef<GSAPTimeline>(null);
    const EACH_FUEL_DURATION = 0.3;

    const [imageWidth, setImageWidth] = React.useState(0);

    useLayoutEffect(() => {
        const height = document.getElementById(
            "ff-fuel-image-container"
        ).offsetHeight;
        setImageWidth(height * IMAGE_ASPECT_RATIO);
    }, []);

    useEffect(() => {
        if (isFuelComplete) {
            onComplete();
        }
    }, [isFuelComplete]);
    useEffect(() => {
        gsap.registerPlugin(TextPlugin);
        const ctx = gsap.context(() => {
            // setTextTl(
            //     gsap
            //         .timeline({ paused: true, repeat: -1, yoyo: true })
            //         .to("#fuel-text", {
            //             autoAlpha: 0,
            //             duration: 0.4
            //         })
            // );
            setFillFuelTl(
                gsap
                    .timeline({ paused: true })
                    .to("#fuel-bar-6", {
                        fill: "white",
                        duration: EACH_FUEL_DURATION
                    })
                    .to("#fuel-bar-5", {
                        fill: "white",
                        duration: EACH_FUEL_DURATION
                    })
                    .to("#fuel-bar-4", {
                        fill: "white",
                        duration: EACH_FUEL_DURATION
                    })
                    .to("#fuel-bar-3", {
                        fill: "white",
                        duration: EACH_FUEL_DURATION
                    })
                    .to("#fuel-bar-2", {
                        fill: "white",
                        duration: EACH_FUEL_DURATION
                    })
                    .to("#fuel-bar-1", {
                        fill: "white",
                        duration: EACH_FUEL_DURATION
                    })
            );
            gsap.set("#fill-fuel-button-inner", {
                transformOrigin: "center center"
            });
            buttonHoverTl.current = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        duration: 0.2
                    }
                })
                .to("#fill-fuel-button-inner", {
                    scale: 1.01
                })
                .fromTo(
                    "#fill-fuel-button-inner .inner",
                    {
                        boxShadow:
                            "inset -3px -1px 3px #F77CA9, inset -5.50162px -3.66775px 3.66775px rgba(252, 252, 252, 0.26), inset -6px -6.67px 7.33549px #D90722, inset 1.83387px 3.66775px 3.66775px #CE031D"
                    },
                    {
                        boxShadow:
                            "inset -3px -1px 3px #F77CA9, inset 2.5px 1.33px 3.66775px rgba(252, 252, 252, 0.26), inset -21px -13.67px 7.33549px #D90722, inset 6.83px 11.67px 3.66775px #CE031D"
                    },
                    "="
                );
            setButtonClickInteraction(
                gsap
                    .timeline({ paused: true })
                    .to("#fill-fuel-button-inner", {
                        scale: 0.92,
                        duration: 0.2
                    })
                    .to("#fill-fuel-button-inner", {
                        scale: 1,
                        duration: 0.2
                    })
            );

            gsap.timeline({ delay: 1 }).add(
                enterTextViaScrambling(
                    document.querySelector("#fuel-text .inner")
                ).play()
            );
        }, mainRef);

        return () => ctx.revert();
    }, []);

    const onButtonMouseEnter = () => {
        buttonHoverTl.current && buttonHoverTl.current.play();
    };

    const onButtonMouseLeave = () => {
        buttonHoverTl.current &&
            buttonHoverTl.current.tweenFromTo(
                buttonHoverTl.current.totalDuration(),
                0,
                {
                    ease: "power4.out"
                }
            );
    };

    useEffect(() => {
        if (fillFuelTl) {
            fillFuelTl.tweenTo(currentFuel * EACH_FUEL_DURATION, {
                ease: "power4.out"
            });
        }
    }, [currentFuel, fillFuelTl]);

    useEffect(() => {
        if (currentFuel === 6) {
            setIsFuelComplete(true);
        }
    }, [currentFuel]);

    useEffect(() => {
        if (isFuelComplete) {
            gsap.to("#fuel-text .inner", {
                text: "You're ready to go!",
                duration: 0.75,
                ease: "none"
            });
        }
    }, [isFuelComplete]);

    const onButtonClick = useCallback(() => {
        if (!isFuelComplete) {
            if (buttonClickInteraction) {
                buttonClickInteraction.restart();
            }
            if (currentFuel < 6) {
                setCurrentFuel((cv) => cv + 2);
            }
        }
    }, [currentFuel, isFuelComplete, buttonClickInteraction]);

    return (
        <div ref={mainRef}>
            <div
                css={{
                    width: "100vw",
                    height: "100vh",
                    backgroundImage: "url(/images/ff-fuel-bg.png)",
                    backgroundSize: "cover",
                    overflow: "scroll"
                }}
            >
                <div
                    css={{
                        height: "100%",
                        padding: `${pxToRem(40)} ${pxToRem(20)}`,
                        display: "grid",
                        gridTemplateRows: "80vh 1fr"
                    }}
                >
                    <div
                        css={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "-5%",
                            svg: {
                                width: imageWidth
                            }
                        }}
                    >
                        <div
                            id="ff-fuel-image-container"
                            css={{
                                position: "relative",
                                height: "80%"
                            }}
                        >
                            <div
                                onClick={onButtonClick}
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                id="fill-fuel-button-inner"
                                css={{
                                    transformOrigin: "center center",
                                    position: "absolute",
                                    width: "30%",
                                    left: "55%",
                                    top: "60%",
                                    transform: "scale(1)",
                                    cursor: "pointer"
                                }}
                            >
                                <div
                                    css={{
                                        width: "100%",
                                        position: "relative",
                                        paddingBottom: "100%",
                                        height: 0,
                                        overflow: "hidden",
                                        borderRadius: "50%",
                                        border: "1.5px solid #FF8B38",
                                        boxShadow: "0px 0px 15px 1px #FF8B38"
                                    }}
                                >
                                    <div
                                        className="inner"
                                        css={{
                                            width: "60%",
                                            height: 0,
                                            paddingBottom: "60%",
                                            borderRadius: "50%",
                                            marginLeft: "20%",
                                            marginTop: "20%",
                                            background: "#FF8B38",
                                            filter: "drop-shadow(0px 0px 3.66775px #F93D55)"
                                        }}
                                    />
                                </div>
                            </div>

                            <FfFuelStationSVG />
                        </div>
                    </div>
                    <div
                        id="fuel-text"
                        css={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            "*": {
                                textAlign: "center"
                            }
                        }}
                    >
                        <Text className="inner" variant="caption" color="white">
                            {DEFAULT_CAPTION_TEXT}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
