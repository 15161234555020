import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";
import { Text } from "../Text";


interface INextButton {
    onClick?: () => void;
}

export const NextButton = ({ onClick }: INextButton) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({
                    repeat: -1
                })
                .to(".bg", {
                    rotate: 360,
                    ease: "none",
                    duration: 20
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div
            onClick={onClick}
            ref={mainRef}
            css={{
                position: "relative",
                width: pxToRem(85),
                height: pxToRem(85),
                cursor: "pointer"
            }}
        >
            <img
                className="bg"
                css={{
                    width: "100%",
                    // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                }}
                src="/images/next-button-bg.png"
            />
            <img
                css={{
                    width: "35%",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: "-20%",
                    // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                }}
                src="/images/next-button-arrow-right.png"
            />
            <Text
                variant="nextButton"
                css={{
                    color: "white",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                Next
            </Text>
        </div>
    );
};
