import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "usehooks-ts";
import { withPrefix } from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";
import { RoughEase } from "gsap/EasePack";
import { Text } from "../Text";
import { CreditAnimations } from "../CreditAnimations";

interface ICreditsScreen {}

const IMAGE_HEIGHT = 1536;
const IMAGE_WIDTH = 2521;

export const CreditsScreen = ({}: ICreditsScreen) => {
    const mainRef = useRef(null);
    const [scale, setScale] = React.useState(1);
    const [marginLeft, setMarginLeft] = React.useState(0);
    const { width: windowWidth, height: windowHeight } = useWindowSize();

    let mainTl = null;

    useLayoutEffect(() => {
        const scaleSetter = () => {
            const windowAspectRatio = window.innerWidth / window.innerHeight;
            const imageAspectRatio = IMAGE_WIDTH / IMAGE_HEIGHT;

            if (windowAspectRatio > imageAspectRatio)
                setScale(window.innerWidth / IMAGE_WIDTH);
            else setScale(window.innerHeight / IMAGE_HEIGHT);
        };
        scaleSetter();
    }, [windowWidth, windowHeight]);

    useLayoutEffect(() => {
        const marginSetter = () => {
            const windowAspectRatio = window.innerWidth / window.innerHeight;
            const imageAspectRatio = IMAGE_WIDTH / IMAGE_HEIGHT;
            setMarginLeft((window.innerWidth - IMAGE_WIDTH * scale) / 2);
        };
        marginSetter();
    }, [scale, windowWidth]);

    useLayoutEffect(() => {
        gsap.registerPlugin(RoughEase);
        const ctx = gsap.context(() => {
            mainTl = gsap.timeline({}).to(mainRef.current, {
                autoAlpha: 1
            });

            gsap.to(".bounce-image", {
                delay: 0.5,
                yPercent: 4,
                repeat: -1,
                yoyo: true,
                duration: 2,
                ease: "rough({ strength: 1, points: 10, template: none.out, taper: none, randomize: true, clamp: false })"
            });

            gsap.to("#gj-credits-animations-container", {
                delay: 0.5,
                yPercent: -2,
                repeat: -1,
                yoyo: true,
                duration: 2,
                ease: "rough({ strength: 1, points: 10, template: none.out, taper: none, randomize: true, clamp: false })"
            });
        }, mainRef);
        return () => {
            ctx.revert();
        };
    }, []);

    const EXIT_ANIM_DURATION = 1.5;
    const exitAnimation = () => {
        const ctx = gsap.context(() => {
            gsap.timeline({
                defaults: {
                    duration: EXIT_ANIM_DURATION
                }
            }).to(mainRef.current, {
                autoAlpha: 0,
                ease: "power4.inOut"
            });
        }, mainRef);
    };
    return (
        <div
            ref={mainRef}
            css={{
                opacity: 0,
                // width: "100vw",
                // height: "100vh",
                position: "fixed",
                background: "black",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden"
            }}
        >
            <img
                css={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    right: 0,
                    bottom: 0,
                    marginTop: "3.5vh",
                    width: "15vw",
                    zIndex: 4,
                    transform: "translateX(-50%)"
                }}
                src="/images/credits-title.png"
            />
            <div
                css={{
                    position: "relative",
                    zIndex: 3,
                    marginLeft,
                    // marginLeft: `calc(((100vw - ${IMAGE_WIDTH * scale}px))/2)`,
                    transform: `scale(${scale})`,
                    transformOrigin: "top left"
                }}
            >
                <div
                    id="gj-credits-animations-container"
                    css={{
                        position: "relative",
                        display: "inline-block"
                        // width: `${IMAGE_WIDTH * scale}px`,
                    }}
                >
                    <img
                        css={{
                            // width: "100%",
                            clipPath: "inset(2px 0 0 0)"
                        }}
                        src="/images/credits-bg.png"
                        alt=""
                    />

                    <div
                        css={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                            // transform: "translateX(-50%)",
                        }}
                    >
                        <CreditAnimations marqueeItemWidth={IMAGE_WIDTH} />
                    </div>
                </div>
            </div>

            <div
                css={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10
                }}
            >
                <div
                    css={{
                        height: "100%",
                        // transform: `scale(${scale})`,
                        transformOrigin: "top left"
                    }}
                >
                    <StaticImage
                        placeholder="none"
                        className="bounce-image"
                        css={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: "auto",
                            width: "100%",
                            objectFit: "cover",
                            objectPosition: "bottom center",
                            clipPath: "inset(2px 0 0 0)"
                        }}
                        quality={90}
                        src="../../../static/images/credits-foreground.png"
                        alt=""
                    />
                </div>
            </div>

            <div
                css={{
                    position: "fixed",
                    bottom: "3vh",
                    left: "2vw",
                    zIndex: 12
                }}
            >
                <a href={withPrefix("/kaju-katli.html")}>
                    <Text
                        className="gj-menu-item"
                        color="white"
                        variant="mainNav"
                    >
                        Kaju Katli
                    </Text>
                </a>
            </div>

            <div
                css={{
                    position: "fixed",
                    bottom: "3vh",
                    right: "2vw",
                    zIndex: 12
                }}
            >
                <TransitionLink
                    className="to-welcome-link"
                    exit={{
                        trigger: ({ exit, node }) => {
                            exitAnimation();
                        },
                        length: EXIT_ANIM_DURATION
                    }}
                    entry={{
                        delay: EXIT_ANIM_DURATION
                    }}
                    to="/"
                >
                    <Text
                        className="gj-menu-item"
                        color="white"
                        variant="mainNav"
                    >
                        &#8635; Restart
                    </Text>
                </TransitionLink>
            </div>
            {/* <img src="/images/credits.png" css={{ */}
            {/*    width: "100%", */}
            {/*    objectPosition: "center center", */}
            {/*    objectFit: "cover" */}
            {/* }} /> */}
        </div>
    );
};
