import React, { useLayoutEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { JV_SCALE } from "./constants";
import { gsap } from "gsap";

const IMAGE_AR = 3438 / 1662;

export const JV_WIDTH_SCALE = 3;

export const JamunVerse = React.memo(
    ({ zIndex, children }: { zIndex: number } & React.PropsWithChildren) => {
        const mainRef = useRef<HTMLDivElement>(null);
        useLayoutEffect(() => {
            const ctx = gsap.context(() => {
                gsap.timeline({}).to(".gj-jv-stars", {
                    rotate: "360deg",
                    duration: 450,
                    repeat: -1,
                    ease: "none"
                });
            }, mainRef);

            return () => ctx.revert();
        }, []);
        return (
            <div
                ref={mainRef}
                css={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"

                    // overflowX: "scroll"
                }}
            >
                <StaticImage
                    className="gj-jv-stars"
                    alt="Jamunverse"
                    src="../../../static/images/jamunverse-stars.png"
                    css={{
                        position: "absolute",
                        top: 0,
                        left: "30%",
                        width: "100vw",
                        height: "100%",
                        zIndex: zIndex - 1,
                        objectFit: "cover",
                        transformOrigin: "center"
                    }}
                    placeholder="none"
                />
                <div
                    css={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                    <div
                        id="gj-jv-image-with-margin"
                        css={{
                            position: "relative",
                            height: `${(100 / IMAGE_AR) * JV_WIDTH_SCALE}vw`,
                            width: "100%"

                            // height: "48.34vw",
                            // marginTop: "calc((100vh - 48.34vw) / 2)"
                        }}
                    >
                        <StaticImage
                            alt="Jamunverse"
                            src="../../../static/images/jamunverse.png"
                            css={{
                                width: "100%",
                                height: "100%",
                                zIndex,
                                marginTop: `calc((${
                                    (100 / IMAGE_AR) * JV_WIDTH_SCALE
                                }vw - 100vh) / -2)`,
                                objectFit: "cover",
                                transform: `scale(${JV_SCALE})`
                                // transformOrigin: "80% center"
                            }}
                            quality={100}
                            placeholder="none"
                        />
                        {children}
                    </div>
                </div>
            </div>
        );
    }
);
