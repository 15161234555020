import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";
import { Text } from "../Text";
import { FF_MAP_ITEMS } from "./data";
import { FfMapItem, IFfMapCountries } from "./FfMapItem";
import {
    enterTextViaScrambling,
    fadeInText
} from "../../utils/text-animations";

interface IFfMap {
    onComplete: () => void;
}

const itemsSequence: IFfMapCountries[] = [
    "pakistan",
    "nepal",
    "india",
    "maldives",
    "myanmar",
    "mauritius",
    "fiji"
];

export const FfMap = ({ onComplete }: IFfMap) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const itemsTl = gsap.timeline({
                paused: true,
                defaults: {
                    duration: 0.15
                }
            });

            itemsSequence.forEach((itemKey) => {
                const innerTl = gsap
                    .timeline({ paused: true })
                    .from(`#${itemKey} .map-item-image`, {
                        scale: 0.8,
                        autoAlpha: 0
                    })
                    .add(
                        enterTextViaScrambling(
                            gsap.utils.toArray(`#${itemKey} .map-item-text`),
                            0.35
                        ).play(),
                        "<+=50%"
                    );

                itemsTl.add(innerTl.play());
            });

            mainTl = gsap
                .timeline({ paused: false, delay: 0 })
                .add(fadeInText(gsap.utils.toArray("#ff-map-title")).play())
                .add(
                    enterTextViaScrambling(
                        gsap.utils.toArray("#ff-map-caption"),
                        4
                    ).play()
                )
                .from(
                    ".compass-image",
                    {
                        autoAlpha: 0
                    },
                    "<"
                )
                .add(itemsTl.play())
                .then(onComplete);
        }, mainRef);

        return () => ctx.revert();
    }, []);

    const HORIZONTAL_PADDING = 20;
    const VERTICAL_PADDING = 20;

    return (
        <div
            ref={mainRef}
            css={{
                display: "grid",
                height: `calc(100vh - ${pxToRem(VERTICAL_PADDING * 2)})`,
                width: `calc(100vw - ${pxToRem(HORIZONTAL_PADDING * 2)})`,
                padding: `${pxToRem(VERTICAL_PADDING)} ${pxToRem(
                    HORIZONTAL_PADDING
                )}`,
                gridTemplateRows: "auto 1fr",
                // overflow: "hidden"
                overflow: "scroll"
            }}
        >
            <div
                className="texts"
                css={{
                    display: "grid",
                    gridGap: pxToRem(18),
                    textAlign: "center"
                }}
            >
                <Text id="ff-map-title" variant="title" color="white">
                    around the world
                </Text>
                <Text
                    id="ff-map-caption"
                    variant="caption"
                    color="white"
                    css={{
                        maxWidth: pxToRem(1000),
                        margin: "auto"
                    }}
                >
                    GULAB JAMUN IS VERY POPULAR ACROSS the entire SOUTH-EAST
                    ASIA, and in farther corners WHERE south asians migrated,
                    like fiji, maldives, mauritius.
                </Text>
            </div>
            <div css={{ position: "relative" }}>
                <div
                    css={{
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div
                        css={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                            textAlign: "center"
                        }}
                    >
                        {FF_MAP_ITEMS.map((item) => (
                            <div
                                css={{
                                    position: "absolute",
                                    ...item.styles
                                }}
                            >
                                <FfMapItem {...item} />
                            </div>
                        ))}
                        <img
                            className="compass-image"
                            css={{
                                marginTop: "10vh",
                                height: "50vh",
                                zIndex: 0
                            }}
                            src="/images/ff-map-compass.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
