import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

interface IPngAnimatedLetter {
    image1Src: string;
    image2Src: string;
    height?: string;
    duration?: number;
    delay?: number;
}


// TODO - add responsiveness: different heights for all breakpoints
export const PngAnimatedLetter = ({
    image1Src,
    image2Src,
    height,
    duration,
    delay
}: IPngAnimatedLetter) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({
                    repeat: -1,
                    yoyo: true
                })
                .from(".image1", {
                    autoAlpha: 1,
                    duration,
                    delay,
                    ease: "none"
                })
                .from(
                    ".image2",
                    {
                        autoAlpha: 0,
                        duration,
                        delay,
                        ease: "none"
                    },
                    "="
                );
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div ref={mainRef} css={{ position: "relative", height: height }}>
            <img
                className="image1"
                src={image1Src}
                css={{ height: "100%", width: "auto" }}
            />
            <img
                className="image2"
                src={image2Src}
                css={{
                    height: "100%",
                    width: "auto",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
            />
        </div>
    );
};
