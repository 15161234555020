import React, { useState } from "react";
import type { HeadFC } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

import { Text } from "../components/Text";
import { StyledComingSoonPage } from "../components/ComingSoonPage/styled";
import DessertRoz from "../assets/svgs/DessertRoz";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { PageLoader } from "../components/PageLoader";
import { withPrefix } from "gatsby";

export default function Home() {
    const backgroundImageRef = useRef();
    const mainElRef = useRef();
    const glowRef = useRef();

    const [pageLoaded, setPageLoaded] = useState(false);


    let a0, a1, a2, tl;

    useEffect(() => {
        setPageLoaded(true);

        a0 = gsap.fromTo(mainElRef.current, { autoAlpha: 0, duration: 0.3 }, {
            autoAlpha: 1
        });
        a1 = gsap.to(backgroundImageRef.current, {
            rotation: "-=360",
            ease: "none",
            repeat: -1,
            repeatDelay: 0,
            duration: 300
        });
        a2 = gsap.fromTo(glowRef.current, { scale: "0.9" }, {
            scale: "1.09",
            yoyo: true,
            yoyoEase: "power1.in",
            repeat: -1,
            repeatDelay: 0,
            duration: 6
        });
        const q = gsap.utils.selector(mainElRef);


        tl = gsap.timeline({
            repeat: -1,
            repeatDelay: 2,
            delay: 1.5
            // paused: true
        });

        tl.fromTo(q(".gj-jeep-container"), {
            xPercent: -62.5, yPercent: 25,
            scale: 1,
            autoAlpha: 0
        }, {
            keyframes: {
                "0%": {
                    autoAlpha: 0
                },
                "30%": {
                    autoAlpha: 1
                },
                ease: "power1.out"
            },
            duration: 3,
            ease: "back.out(2)",
            xPercent: 125,
            yPercent: -50
        });

        tl.to(q(".gj-jeep-container"), {
            keyframes: {
                autoAlpha: [1, 0],
                ease: "power4.out"
            },
            scale: 0.4,
            duration: 1,
            ease: "power4.out",
            xPercent: 375,
            yPercent: -150
        });

        tl.set(q(".gj-jeep-container"), { x: 0, y: 0 }, ">");

        // tl.fromTo(q(".gj-jeep-container"), {x: "250%", y: "-100%", immediateRender: false}, {
        //     x: 0,
        //     y: 0,
        //     keyframes: {
        //         autoAlpha: [0, 0, 0, 0, 0, 1, 0.2, 0.2, 0.9],
        //         scale: [0.8, 0.2, 1],
        //         ease: "power2.inOut"
        //     },
        //     duration: totalDuration / 2,
        //     ease: "power2.inOut"
        // });

        return () => {
            a0 && a0.kill();
            a1 && a1.kill();
            a2 && a2.kill();
            tl && tl.kill();
        };
    }, []);

    return (
        <div>
            {/*<Head>*/}
            {/*    <title>Gulab Jamun | Dessert Roz</title>*/}
            {/*    <meta name="description"*/}
            {/*          content="Dessert Roz is a celebration of desserts & mithai from across South Asia."/>*/}
            {/*    <link rel="icon" href="../images/favicon.png"/>*/}
            {/*</Head>*/}

            <main>
                <PageLoader active={!pageLoaded}>
                    <StyledComingSoonPage ref={mainElRef}>
                        <div className="gj-main-bg-container">
                            <div ref={backgroundImageRef}
                                 style={{ backgroundImage: `url(${withPrefix("/images/main-bg.png")})` }}
                                 className="gj-main-bg">
                            </div>
                        </div>
                        <div className="content">
                            <div className="gj-dessert-roz-logo-container">
                                <div className="gj-dessert-roz-logo">
                                    <DessertRoz />
                                </div>
                            </div>
                            <div className="gj-gulab-jamun-sphere-container">

                                <div className="gj-gulab-jamun-sphere-and-glow">
                                    <div className="gj-jeep-container">
                                        <div className="gj-jeep gj-jeep-side">
                                            <StaticImage placeholder="none" loading="eager"
                                                         src="../images/jeep.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="gj-gulab-jamun-sphere">
                                        <StaticImage placeholder="none" loading="eager"
                                                     src="../images/gulab-jamun-sphere.png" alt="" />
                                    </div>
                                    <div className="gj-gulab-jamun-ring">
                                        <StaticImage placeholder="none" loading="eager" src="../images/spark.png"
                                                     alt="" />
                                    </div>
                                    <div ref={glowRef} className="gj-gulab-jamun-sphere-glow">
                                        <StaticImage placeholder="none" loading="eager" src="../images/glow.png"
                                                     alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="gj-coming-soon-and-gulab-jamun-text">
                                <div className="gj-coming-soon-text">
                                    <StaticImage placeholder="none" className="gj-coming-soon-streak" loading="eager"
                                                 src="../images/coming-soon-streak.png" alt="" />
                                    <Text variant="caption">Coming soon</Text>
                                    <StaticImage placeholder="none" className="gj-coming-soon-streak right"
                                                 loading="eager"
                                                 src="../images/coming-soon-streak.png" alt="" />
                                </div>
                                <div className="gj-gulab-jamun-text">
                                    <StaticImage placeholder="none"
                                                 loading="eager"
                                                 src="../images/gulab-jamun-text.png" alt="" />
                                </div>
                            </div>

                            <div className="gj-menu">
                                <a href={withPrefix("/kaju-katli.html")}>
                                    <Text
                                        className="gj-menu-item"
                                        mixBlendMode="difference"
                                        variant="mainNav"
                                    >
                                        Kaju
                                        Katli
                                    </Text>
                                </a>
                                <a href={withPrefix("/about.html")}>
                                    <Text
                                        className="gj-menu-item"
                                        mixBlendMode="difference"
                                        variant="mainNav"
                                    >
                                        About
                                    </Text>
                                </a>
                            </div>
                        </div>
                    </StyledComingSoonPage>
                </PageLoader>
            </main>

            <footer>
            </footer>
        </div>
    );
}


export const Head: HeadFC = () => <title>Gulab Jamun | Dessert Roz</title>;
