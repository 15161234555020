import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../../utils/px-to-rem";
import { Text } from "../../Text";

interface IPlayButton {
    onClick?: () => void;
}

export const PlayButton = ({ onClick }: IPlayButton) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({
                    repeat: -1
                })
                .to("img", {
                    rotate: 360,
                    ease: "none",
                    duration: 10
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div
            onClick={onClick}
            ref={mainRef}
            css={{
                position: "relative",
                width: pxToRem(100),
                height: pxToRem(100),
                cursor: "pointer"
            }}
        >
            <img
                css={{
                    width: "100%",
                    filter: `drop-shadow(0px 0px 3px #FF8B38)`
                }}
                src="/images/ff-home-play-button-ring.png"
            />
            <Text
                variant="funFactsHomePlayButton"
                css={{
                    color: "#FF8B38",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    filter: `drop-shadow(4px 0px 6px #FF8B38)`
                }}
            >
                Play
            </Text>
        </div>
    );
};
