import React, { useEffect, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import TransitionLink, {
    TransitionPortal
} from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { NextButton } from "../NextButton";
import { pxToRem } from "../../utils/px-to-rem";
import { Hyperspace } from "../Hyperspace";

interface IWithNextButton {
    position?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
    type: "hyperspace" | "default";
    showButton?: boolean;
    to: string;
    hyperspaceLineCount?: number;  
}

const EXIT_ANIM_DURATION = 2;
export const WithNextButton = ({
    children,
    position = "bottomRight",
    showButton = false,
    to,
    type,
    hyperspaceLineCount = 1000
}: IWithNextButton & React.PropsWithChildren) => {
    const mainRef = useRef(null);

    useEffect(() => {
        if (showButton) {
            gsap.to(".button-container", {
                autoAlpha: 1
            });
        } else {
            gsap.to(".button-container", {
                autoAlpha: 0
            });
        }
    }, [showButton]);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap.timeline({});
        }, mainRef);

        return () => ctx.revert();
    }, []);

    const exitAnimation = () => {
        const ctx = gsap.context(() => {
            gsap.timeline({
                defaults: {
                    duration: EXIT_ANIM_DURATION * 0.75
                }
            })
                .set(document.querySelector("#hyperspace"), {
                    display: "block"
                })
                .to(".non-hyperspace", {
                    autoAlpha: 0,
                    ease: "power4.inOut",
                    duration: EXIT_ANIM_DURATION * 0.25
                })
                .to(
                    document.querySelector("#hyperspace"),
                    {
                        autoAlpha: 1
                    },
                    "<+=5%"
                )
                .to(document.querySelector("#hyperspace"), {
                    autoAlpha: 0,
                    duration: EXIT_ANIM_DURATION * 0.25,
                    ease: "power3.out"
                })
                .set(document.querySelector("#hyperspace"), {
                    display: "none"
                });
        }, mainRef);
    };

    const distanceFromEdge = pxToRem(24);
    return (
        <div
            ref={mainRef}
            css={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}
        >
            {/* <TransitionPortal> */}
            <div
                id="hyperspace"
                className="hyperspace"
                css={{
                    display: "none",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: 200,
                    opacity: 0
                }}
            >
                <Hyperspace count={hyperspaceLineCount} />
            </div>
            {/* </TransitionPortal> */}
            <div className="non-hyperspace">
                {children}
                <div
                    className="button-container"
                    css={{
                        opacity: 0,
                        position: "absolute",
                        top:
                            ["topRight", "topLeft"].indexOf(position) > -1
                                ? distanceFromEdge
                                : "initial",
                        bottom:
                            ["bottomRight", "bottomLeft"].indexOf(position) > -1
                                ? distanceFromEdge
                                : "initial",
                        left:
                            ["bottomLeft", "topLeft"].indexOf(position) > -1
                                ? distanceFromEdge
                                : "initial",
                        right:
                            ["topRight", "bottomRight"].indexOf(position) > -1
                                ? distanceFromEdge
                                : "initial"
                    }}
                >
                    {type === "hyperspace" ? (
                        <TransitionLink
                            exit={{
                                trigger: ({ exit, node }) => {
                                    exitAnimation();
                                },
                                length: EXIT_ANIM_DURATION
                            }}
                            entry={{
                                trigger: ({ node }) => {
                                    gsap.from(node, {
                                        autoAlpha: 0,
                                    })
                                },
                                delay: EXIT_ANIM_DURATION * 1.2
                            }}
                            to={to}
                        >
                            {/*<Link to="/fun-facts/fact-1-puzzle">*/}
                            <NextButton />
                        </TransitionLink>
                    ) : null}
                    {type === "default" ? (
                        <AniLink duration={1.2} cover to={to} bg="#000000">
                            {/*<Link to="/fun-facts/fact-1-puzzle">*/}
                            <NextButton />
                        </AniLink>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
