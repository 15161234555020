import React, { useState } from "react";

import type { HeadFC } from "gatsby";
import { WithNextButton } from "../../components/WithNextButton";
import { FfRoad } from "../../components/FfRoad";

import { SEO } from "../../components/Seo";

export const Head: HeadFC = () => <SEO title="Gulab Jamun Road" />;

const FunFact2TriviaPage = () => {
    const [showButton, setShowButton] = useState(false);
    return (
        <main>
            <WithNextButton
                to="/fun-facts/fact-3-puzzle"
                type="hyperspace"
                showButton={showButton}
            >
                <FfRoad onComplete={() => setShowButton(true)} />
            </WithNextButton>
        </main>
    );
};

export default FunFact2TriviaPage;

