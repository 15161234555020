import { pxToRem } from "../utils/px-to-rem";
import * as CSS from "csstype";
import { between } from "polished";

type ITextStyle = Pick<
    CSS.Properties,
    | "fontSize"
    | "fontFamily"
    | "fontWeight"
    | "lineHeight"
    | "letterSpacing"
    | "fontVariant"
    | "textTransform"
    | "fontStyle"
>;

export interface IThemeTypography {
    caption: ITextStyle;
    smallCaption: ITextStyle;
    mapItemText: ITextStyle;
    title: ITextStyle;
    ffPollPieText: ITextStyle;
    mainNav: ITextStyle;
    encyclopediaNav: ITextStyle;
    fitb: ITextStyle;
    funFactsHomePlayButton: ITextStyle;
    nextButton: ITextStyle;
    pilowlava: ITextStyle;
    welcomeToText: ITextStyle;
    credits: ITextStyle;
}

export interface IThemeInterface {
    colors: {
        primary: string;
    };
    typography: IThemeTypography;
}

const MIN_SCREEN_SIZE = pxToRem(320);
const MAX_SCREEN_SIZE = pxToRem(2560);

const TYPOGRAPHY_SCALING_FACTOR = 2;
const fluidSize = (min: number, max: number) => {
    return between(
        pxToRem(min),
        pxToRem(max),
        MIN_SCREEN_SIZE,
        MAX_SCREEN_SIZE
    );
};

export const theme: IThemeInterface = {
    typography: {
        caption: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(14, 21),
            fontWeight: 400,
            lineHeight: fluidSize(19.6, 30),
            letterSpacing: "0.15em",
            textTransform: "uppercase"
        },
        welcomeToText: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(22, 36),
            fontWeight: 400,
            lineHeight: fluidSize(28, 48),
            letterSpacing: "0em",
            textTransform: "uppercase"
        },
        smallCaption: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(11, 18),
            fontWeight: 400,
            lineHeight: fluidSize(14, 25),
            // letterSpacing: "0.15em",
            textTransform: "uppercase"
        },
        mapItemText: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(14, 25),
            fontWeight: 400,
            lineHeight: fluidSize(19.6, 35),
            letterSpacing: "0.05em",
            textTransform: "uppercase"
        },
        title: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(45 / TYPOGRAPHY_SCALING_FACTOR, 45),
            fontWeight: 400,
            lineHeight: fluidSize(45 / TYPOGRAPHY_SCALING_FACTOR, 45),
            letterSpacing: "0.2em",
            textTransform: "uppercase"
        },
        ffPollPieText: {
            fontFamily: "Space Mono",
            fontSize: fluidSize(
                80 / 19.4 / 1.5 / TYPOGRAPHY_SCALING_FACTOR,
                80 / 19.4 / 1.5
            ),
            fontWeight: "bold",
            lineHeight: fluidSize(
                140 / 19.4 / 1.5 / TYPOGRAPHY_SCALING_FACTOR,
                140 / 19.4 / 1.5
            )
        },
        mainNav: {
            fontFamily: "Cotham Sans",
            fontSize: pxToRem(20),
            fontWeight: 400,
            lineHeight: pxToRem(28)
        },
        encyclopediaNav: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(27 / TYPOGRAPHY_SCALING_FACTOR, 27),
            fontWeight: 400,
            lineHeight: fluidSize(27 / TYPOGRAPHY_SCALING_FACTOR, 27),
            textTransform: "uppercase"
        },
        fitb: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(80 / TYPOGRAPHY_SCALING_FACTOR, 80),
            fontWeight: 400,
            lineHeight: fluidSize(80 / TYPOGRAPHY_SCALING_FACTOR, 80),
            textTransform: "uppercase"
        },
        funFactsHomePlayButton: {
            fontFamily: "Sligoil Micro",
            fontSize: fluidSize(18 / TYPOGRAPHY_SCALING_FACTOR, 18),
            fontWeight: 400,
            lineHeight: fluidSize(25 / TYPOGRAPHY_SCALING_FACTOR, 25),
            textTransform: "uppercase"
        },
        nextButton: {
            fontFamily: "VCR OSD Mono",
            fontSize: fluidSize(18, 18),
            fontWeight: 400,
            lineHeight: fluidSize(25, 25),
            textTransform: "uppercase"
        },
        pilowlava: {
            fontFamily: "Pilowlava",
            fontSize: fluidSize(18, 18),
            fontWeight: 400,
            lineHeight: fluidSize(25, 25),
            textTransform: "uppercase"
        },
        // this needs to be in px because the container scales with the screen size
        credits: {
            fontFamily: "Sligoil Micro",
            fontSize: "80px",
            fontWeight: 900,
            lineHeight: 1,
            textTransform: "uppercase"
        }
    },
    colors: {
        primary: "#0070f3"
    }
};
