import React, { useLayoutEffect, useMemo, useRef } from "react";
import { gsap } from "gsap";
import { ILetters } from "../PilowlavaText";

const WHITE_LETTERS = [
    "https://res.cloudinary.com/candyman/image/upload/v1688622517/gj/letters/0-G-gulabjamun-white.svg",
    "https://res.cloudinary.com/candyman/image/upload/v1688622525/gj/letters/1-u-gulabjamun-white.svg",
    "https://res.cloudinary.com/candyman/image/upload/v1688622516/gj/letters/2-l-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622535/gj/letters/3-a-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622539/gj/letters/4-b-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622533/gj/letters/6-J-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622530/gj/letters/7-a-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622519/gj/letters/8-m-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622530/gj/letters/9-u-gulabjamun-white.svg",

    "https://res.cloudinary.com/candyman/image/upload/v1688622529/gj/letters/10-n-gulabjamun-white.svg"
];

const positions = [
    {
        left: 10,
        top: 10
    },
    {
        left: 15,
        top: 35
    },
    {
        left: 40,
        top: 60
    },
    {
        left: 30,
        top: 20
    },
    {
        left: 60,
        top: 60
    },
    {
        left: 70,
        top: 20
    },
    {
        left: 10,
        top: 60
    },
    {
        left: 25,
        top: 50
    },
    {
        left: 50,
        top: 15
    },
    {
        left: 60,
        top: 35
    }
];

export const GjTextSpreadOut = React.memo(() => {
    const mainRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            // gsap.to(".letter", {
            //     // scale: gsap.utils.distribute({
            //     //     base: 0.5,
            //     //     amount: 1.1,
            //     //     from: "center"
            //     // }),
            //     y: gsap.utils.random(-window.innerHeight / 2, window.innerHeight / 2, 1),
            //     x: gsap.utils.distribute({
            //         base: window.innerWidth / 10,
            //         amount: window.innerWidth / 5,
            //         from: "center"
            //     })
            // })
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, []);
    const whiteLetters = useMemo<ILetters>(
        () =>
            WHITE_LETTERS.map((url) => {
                let retVal;
                retVal = {
                    el: <img src={`${url}`} />
                };
                return retVal;
            }),
        []
    );
    return (
        <div
            ref={mainRef}
            className="gj-text-spread-out"
            css={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                img: {
                    height: "2.5vw"
                }
            }}
        >
            {whiteLetters.map((letter, i) => (
                <div
                    className="letter"
                    key={i}
                    css={{
                        position: "absolute",
                        left: `${positions[i].left * 1.1 + 10}%`,
                        top: `${positions[i].top * 1.1 + 5}%`
                    }}
                >
                    {letter.el}
                </div>
            ))}
        </div>
    );
});
