import React, { useState } from "react";

import type { HeadFC } from "gatsby";
import { FfTwitterPoll } from "../../components/FfTwitterPoll";
import { WithNextButton } from "../../components/WithNextButton";

import { SEO } from "../../components/Seo";

export const Head: HeadFC = () => <SEO title="Twitter Poll" />;

const FunFact1TriviaPage = () => {
    const [showButton, setShowButton] = useState(false);
    return (
        <main>
            <WithNextButton
                to="/fun-facts/fact-2-puzzle"
                type="hyperspace"
                showButton={showButton}
            >
                <FfTwitterPoll onComplete={() => setShowButton(true)} />
            </WithNextButton>
        </main>
    );
};

export default FunFact1TriviaPage;

