import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { NewShader } from "../ChaashniBackground/NewShader";
import { JV_Z_INDICES } from "../Jamunverse/constants";

export const PageBackground = () => {
    const mainRef = useRef(null);
    const mainTl = React.useRef<GSAPTimeline>(null);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl.current = gsap.timeline({}).to(mainRef.current, {
                duration: 1,
                autoAlpha: 1
            });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div
            ref={mainRef}
            css={{
                opacity: 0,
                position: "fixed",
                zIndex: JV_Z_INDICES.chaashni,
            }}
        >
            <NewShader />
            {/* {canvasFilterDecisionMade ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>{canvasFilterCompatible ? <NewShader /> : <NewShader />}</>
            ) : null} */}
        </div>
    );
};
