import * as React from "react";

function SvgLedikeniClipPath(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 845 399"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M110 31.5C128.833 26 180.8 13.4 238 7l77-6h109l139.5 6 66 7 57 9.5L736 41l34 20 30.5 30.5 33 50.5 10.5 41v48l-10.5 48-20 37.5L776 355l-69.5 32-65.5 11.5H221.5l-73-11.5-48-15.5L55 340l-36.5-43.5L1 239v-62l17.5-61L63 61l47-29.5z"
                stroke="#000"
            />
        </svg>
    );
}

export default SvgLedikeniClipPath;
