import * as React from "react";

function SvgScrollButtonCircle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 91 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M77.646 79.72c-8.156 8.155-19.685 10.549-31.283 8.823-11.6-1.726-23.207-7.567-31.43-15.79C6.71 64.53 1.786 54.053 1.353 43.9.921 33.762 4.961 23.924 14.717 16.905 26.351 8.535 37.882 2.87 48.537 1.342c10.627-1.524 20.389 1.067 28.565 9.242 8.178 8.179 12.438 21.347 12.573 34.573.135 13.227-3.856 26.39-12.03 34.563z"
                stroke="#FF8B38"
            />
        </svg>
    );
}

export default SvgScrollButtonCircle;
