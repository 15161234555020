import React, { useMemo } from "react";
import { ILetters, IPilowlavaText, PilowlavaText } from "./index";

const LETTERS = [
    "/images/letters/r.png",
    "/images/letters/a.png",
    "/images/letters/s.png",
    "/images/letters/g.png",
    "/images/letters/u.png",
    "/images/letters/l.png",
    "/images/letters/l.png",
    "/images/letters/a.png"
];

const WHITE_LETTERS = [
    "/images/letters/white-r.png",
    "/images/letters/white-a.png",
    "/images/letters/white-s.png",
    "/images/letters/white-g.png",
    "/images/letters/white-u.png",
    "/images/letters/white-l.png",
    "/images/letters/white-l.png",
    "/images/letters/white-a.png"
];



export const RasgullaText = ({
                               ...props
                           }: Omit<IPilowlavaText, "name" | "letters" | "whiteLetters">) => {
    const letters = useMemo<ILetters>(
        () =>
            LETTERS.map((url) => ({
                el: <img src={`${url}`} />,
                type: "letter"
            })),
        []
    );

    const whiteLetters = useMemo<ILetters>(
        () =>
            WHITE_LETTERS.map((url) => ({
                el: <img src={`${url}`} />,
                type: "letter"
            })),
        []
    );
    return (
        <PilowlavaText
            name="rasgulla-sun"
            letters={letters}
            whiteLetters={whiteLetters}
            {...props}
        />
    );
};
