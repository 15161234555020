import React, { useState } from "react";

import type { HeadFC } from "gatsby";
import loadable from "@loadable/component";

import { WithNextButton } from "../../components/WithNextButton";

import { SEO } from "../../components/Seo";

export const Head: HeadFC = () => <SEO title="Trivia 1" />;

const FunFactsConstellation = loadable(
    () => import("../../components/FunFactsConstellation")
);




const FunFact1PuzzlePage = () => {
    const [showButton, setShowButton] = useState(false);
    return (
        <main>
            <WithNextButton
                to="/fun-facts/fact-1-trivia"
                type="default"
                showButton={showButton}
            >
                <FunFactsConstellation onComplete={() => setShowButton(true)} />
            </WithNextButton>
        </main>
    );
};

export default FunFact1PuzzlePage;