import React, { useLayoutEffect, useState } from "react";

import type { HeadFC } from "gatsby";
import { CreditsScreen } from "../components/CreditsScreen";
import { BasicLoader } from "../components/BasicLoader";

import { SEO } from "../components/Seo";

export const Head: HeadFC = () => <SEO title="Credits" />;

const Credits = () => {
    return (
        <main>
            <BasicLoader />
            <CreditsScreen />
        </main>
    );
};

export default Credits;

