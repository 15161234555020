import { useEffect, useLayoutEffect, useState } from "react";
import { gsap } from "gsap";

export const useImagesLoaded = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useLayoutEffect(() => {
        Promise.all(
            Array.from(gsap.utils.toArray<HTMLImageElement>("img"))
                .filter((img) => !img.complete)
                .map(
                    (img) =>
                        new Promise((resolve) => {
                            img.onload = img.onerror = resolve;
                        })
                )
        ).then(() => {
            setImagesLoaded(true);
        });
    }, []);

    return { imagesLoaded };
};
