import React from "react";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./src/themes";
import { UnstyledLayout } from "./src/components/UnstyledLayout";
import "./src/assets/css/font-face-declarations.css";
import { Script } from "gatsby";

export const wrapRootElement = ({ element }) => {
    return (
        <ThemeProvider theme={theme}>
            {/*<Script src="https://cdn.jsdelivr.net/npm/context-filter-polyfill/dist/index.min.js" />*/}

            {/*<script src="https://cdn.jsdelivr.net/npm/context-filter-polyfill/dist/index.min.js" />*/}
            <UnstyledLayout>{element}</UnstyledLayout>
        </ThemeProvider>
    );
};
