import React from "react";

import type { HeadFC } from "gatsby";

import loadable from "@loadable/component";
import { SEO } from "../components/Seo";
import WelcomeSectionSSR from "../components/WelcomePage";

const WelcomeSection = loadable(() => import("../components/WelcomePage"));

const WelcomePage = () => {
    return (
        <main>
            <WelcomeSectionSSR />
        </main>
    );
};

export default WelcomePage;

export const Head: HeadFC = () => <SEO />;
