import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { JV_Z_INDICES } from "./constants";
import { random } from "lodash";
import { StaticImage } from "gatsby-plugin-image";
import { TO_ACTIVE_DURATION } from "../PilowlavaText";
import { useWhyDidYouUpdate } from "ahooks";

const PlanetImage = ({
    id,
    onImageLoaded = () => {}
}: {
    id: IRoundPlanetID;
    onImageLoaded?: () => void;
}) => {
    if (id === "rasgulla-sun") {
        return (
            <StaticImage
                onLoad={onImageLoaded}
                placeholder="none"
                alt=""
                quality={100}
                css={{
                    width: "100%",
                    position: "relative",
                    zIndex: 1

                    // filter: "drop-shadow(0px 0px 41.7991px #FCFCFC)"
                }}
                src={`../../../static/images/rasgulla-sun-planet.png`}
            />
        );
    }
    if (id === "gulabjamun") {
        return (
            <StaticImage
                onLoad={onImageLoaded}
                placeholder="none"
                alt=""
                quality={100}
                css={{
                    width: "100%",
                    position: "relative",
                    zIndex: 1

                    // filter: "drop-shadow(0px 0px 41.7991px #FCFCFC)"
                }}
                src={`../../../static/images/gulabjamun-planet.png`}
            />
        );
    }
    if (id === "jhurra") {
        return (
            <StaticImage
                onLoad={onImageLoaded}
                placeholder="none"
                alt=""
                quality={100}
                css={{
                    width: "100%",
                    position: "relative",
                    zIndex: 1

                    // filter: "drop-shadow(0px 0px 41.7991px #FCFCFC)"
                }}
                src={`../../../static/images/jhurra-planet.png`}
            />
        );
    }
    if (id === "pantua") {
        return (
            <StaticImage
                onLoad={onImageLoaded}
                placeholder="none"
                alt=""
                quality={100}
                css={{
                    width: "100%",
                    position: "relative",
                    zIndex: 1

                    // filter: "drop-shadow(0px 0px 41.7991px #FCFCFC)"
                }}
                src={`../../../static/images/pantua-planet.png`}
            />
        );
    }
    if (id === "kalajam") {
        return (
            <StaticImage
                onLoad={onImageLoaded}
                placeholder="none"
                alt=""
                quality={100}
                css={{
                    width: "100%",
                    position: "relative",
                    zIndex: 1

                    // filter: "drop-shadow(0px 0px 41.7991px #FCFCFC)"
                }}
                src={`../../../static/images/kalajam-planet.png`}
            />
        );
    }
    if (id === "ledikeni") {
        return (
            <StaticImage
                onLoad={onImageLoaded}
                placeholder="none"
                alt=""
                quality={100}
                css={{
                    width: "100%",
                    position: "relative",
                    zIndex: 1,
                    transform: "rotate(54deg)"

                    // filter: "drop-shadow(0px 0px 41.7991px #FCFCFC)"
                }}
                src={`../../../static/images/ledikeni-planet.png`}
            />
        );
    }
};

export type IRoundPlanetID =
    | "rasgulla-sun"
    | "gulabjamun"
    | "jhurra"
    | "pantua"
    | "kalajam"
    | "ledikeni";
export const RoundPlanet = ({
    id,
    scrollValue,
    widthPercentage,
    left,
    bottom,
    top,
    right,
    children,
    setActiveTl,
    immediateActive,
    onImageLoaded,
    enabled
}: {
    scrollValue?: number;
    immediateActive?: boolean;
    id: IRoundPlanetID;
    widthPercentage: number;
    left?: string;
    right?: string;
    bottom?: string;
    top?: string;
    setActiveTl: (GSAPTimeline) => void;
    onImageLoaded?: () => void;
    enabled?: boolean;
} & React.PropsWithChildren) => {
    const mainRef = useRef(null);
    const tl = useRef<GSAPTimeline>(null);
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if (!enabled) return;
            const bufferPercentage = 10;
            const finalWidth = window.innerWidth * 0.5;
            const finalX = (bufferPercentage * window.innerWidth) / 100;
            const finalY =
                window.innerHeight -
                (bufferPercentage * window.innerWidth) / 100;
            const originalWidth = (window.innerWidth * widthPercentage) / 100;
            const planetBox = gsap.utils
                .toArray<HTMLElement>(`#${id}`)[0]
                .getBoundingClientRect();

            const effectiveX = planetBox.x + planetBox.width / 2;
            const effectiveY = planetBox.y + planetBox.height / 2;

            const scale = finalWidth / originalWidth;

            const finalEffectiveX = finalX;
            const finalEffectiveY = finalY;

            tl.current = gsap
                .timeline({
                    data: {
                        instanceof: "RoundPlanet"
                    },
                    paused: true,
                    defaults: {
                        duration: TO_ACTIVE_DURATION * 0.8,
                        ease: "power3.out"
                    }
                })
                .to(".children", {
                    autoAlpha: 0,
                    duration: 0.5
                })
                .to(
                    ".planet",
                    {
                        transformOrigin: "center",
                        rotate: id === "ledikeni" ? "70deg" : `135deg`,
                        // filter: "drop-shadow(0px -3px 31px #FF8B38)",
                        x: () => -(effectiveX - finalEffectiveX),
                        y: () => -(effectiveY - finalEffectiveY),
                        // left: () => finalX,
                        // bottom: () => finalY,
                        // top: "auto",
                        // right: "auto",
                        scale
                    },
                    "="
                )
                .to(
                    ".planet-glow",
                    {
                        autoAlpha: 1
                        // left: () => finalX,
                        // bottom: () => finalY,
                        // top: "auto",
                        // right: "auto",
                        // scale
                    },
                    "="
                );
            if (immediateActive) {
                tl.current.progress(1);
            }
            setActiveTl(tl.current);
        }, mainRef);

        return () => ctx.revert();
    }, [scrollValue, enabled]);

    return (
        <div ref={mainRef}>
            <div
                css={{
                    willChange: "transform",
                    position: "absolute",
                    left,
                    bottom,
                    top,
                    right,
                    zIndex: JV_Z_INDICES.inactivePlanets
                }}
            >
                <div
                    id={id}
                    className="planet"
                    css={{
                        transform:
                            id === "ledikeni"
                                ? "rotate(225deg)"
                                : id === "gulabjamun"
                                ? "rotate(180deg)"
                                : "",
                        willChange: "transform",
                        transformOrigin: "center",
                        width: `${widthPercentage}vw`,
                        height:
                            id === "ledikeni" ? "auto" : `${widthPercentage}vw`
                        // clipPath: "ellipse(48% 50% at 50% 50%)"
                    }}
                >
                    <div
                        className="planet-glow"
                        css={{
                            position: "absolute",

                            bottom: 0,
                            right: 0,
                            transform: "scale(2)",
                            // position: "fixed",
                            // left: 0,
                            // bottom: 0,
                            left: id === "ledikeni" ? "-0.5vw" : `0`,
                            top: id === "ledikeni" ? "-2.5vw" : `0`,
                            opacity: 0
                        }}
                    >
                        <StaticImage
                            placeholder="none"
                            quality={60}
                            src="../../images/planet-active-glow.png"
                            alt=""
                        />
                    </div>
                    <div className="children">{children}</div>
                    <PlanetImage onImageLoaded={onImageLoaded} id={id} />
                </div>
            </div>
        </div>
    );
};
