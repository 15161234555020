import styled from "@emotion/styled";

export const StyledPageLoader = styled.div(({theme}) => ({
    ".pageloader": {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#000"
    },
    ".pageloader-children": {
        opacity: 0,
    }
}));
