import React, { useLayoutEffect, useState } from "react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

interface IUseCanvasImageSequence {
    imageWidth: number;
    imageHeight: number;
    mainRef: React.RefObject<HTMLDivElement>;
    imageUrls: string[];
}
export const useCanvasImageSequence = ({
    imageHeight,
    imageWidth,
    mainRef,
    imageUrls
}: IUseCanvasImageSequence) => {
    const [loaded, setLoaded] = useState(false);
    const [canvasScale, setCanvasScale] = useState(0);
    const [frames, setFrames] = useState([]);
    const [scaledBy, setScaledBy] = useState<"width" | "height">("width");

    useIsomorphicLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const ctx = gsap.context(() => {

            const localFrames = [];
            const loadFrames = () => {
                return imageUrls.map((imgUrl, i) => {
                    localFrames[i] = new Image(
                        window.innerWidth,
                        window.innerHeight
                    );
                    localFrames[i].src = imgUrl;
                    return localFrames[i].onload;
                });
            };

            Promise.all(loadFrames()).then(() => {
                setFrames(localFrames);
                setLoaded(true);
            });

            const scaleSetter = () => {
                const canvasAR = imageWidth / imageHeight;
                const windowAR = window.innerWidth / window.innerHeight;

                if (canvasAR > windowAR) {
                    setCanvasScale(window.innerHeight / imageHeight);
                    setScaledBy("height");
                } else {
                    setCanvasScale(window.innerWidth / imageWidth);
                    setScaledBy("width");
                }
            };

            scaleSetter();

            window.addEventListener(
                "resize",
                () => {
                    scaleSetter();
                },
                false
            );

            //
            // if (loaded) {
            //     mainTl = gsap
            //         .timeline({
            //             scrollTrigger: {
            //                 trigger: mainRef.current,
            //                 start: "top 1px",
            //                 pin: true,
            //                 scrub: true,
            //                 end: `+=${window.innerHeight * 8}`
            //             }
            //         })
            //         .to(frameNumberTweener, {
            //             value: count - 1,
            //             onUpdate: () => {
            //                 const currentFrame = Math.round(
            //                     frameNumberTweener.value
            //                 );
            //                 //const img = new Image;
            //
            //                 //img.onload = function() {
            //                 context.clearRect(
            //                     0,
            //                     0,
            //                     canvas.width,
            //                     canvas.height
            //                 );
            //                 // context.imageSmoothingEnabled = true;
            //                 // context.imageSmoothingQuality = "high";
            //
            //                 context.drawImage(
            //                     frames[currentFrame],
            //                     0,
            //                     0,
            //                     canvas.width,
            //                     canvas.height
            //                 );
            //                 //};
            //             }
            //         });
            // }
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return {
        loaded,
        canvasScale,
        frames,
        scaledBy
    };
};
