import * as React from "react";

function SvgScrollButtonCircleWhite(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 91 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.378 23.187a33.452 33.452 0 014.49-4.675l.326.378a36.88 36.88 0 012.523-1.985c.787-.566 1.574-1.12 2.36-1.661l-.283-.412a125.188 125.188 0 014.846-3.177l.264.424a106.265 106.265 0 014.973-2.915l-.24-.438A89.645 89.645 0 0131.793 6.1l.212.452a73.657 73.657 0 015.302-2.24l-.175-.469a60.594 60.594 0 015.505-1.783l.13.483a48.727 48.727 0 015.607-1.178l-.073-.495a40.347 40.347 0 015.771-.443l.002.5a33.717 33.717 0 015.681.456l.083-.493c1.906.32 3.771.81 5.59 1.48l-.172.469a30.455 30.455 0 015.131 2.458l.257-.43a33.966 33.966 0 014.684 3.39l-.326.379c.71.61 1.41 1.259 2.1 1.948.73.73 1.428 1.5 2.096 2.306l.385-.32a38.492 38.492 0 013.608 5.197l-.432.252a45.265 45.265 0 012.757 5.621l.462-.19a52.973 52.973 0 012.035 5.992l-.482.133c.551 1.999.997 4.05 1.336 6.134l.494-.08c.338 2.073.571 4.177.7 6.291l-.5.03c.128 2.09.151 4.191.071 6.282l.5.019a62.198 62.198 0 01-.562 6.306l-.495-.07a57.194 57.194 0 01-1.212 6.158l.484.123a51.33 51.33 0 01-1.933 6.025l-.465-.183a43.562 43.562 0 01-2.686 5.653l.435.247a37.063 37.063 0 01-3.574 5.22l-.387-.319a32.148 32.148 0 01-2.091 2.308c-.688.688-1.4 1.335-2.133 1.941l.319.386a30.898 30.898 0 01-4.838 3.282l-.24-.439a32.077 32.077 0 01-5.28 2.3l.158.474a36.163 36.163 0 01-5.682 1.389l-.08-.494c-1.884.305-3.81.471-5.758.506l.01.5a46.598 46.598 0 01-5.849-.271l.054-.497a51.469 51.469 0 01-5.717-.954l-.11.488a56.315 56.315 0 01-5.637-1.58l.16-.474a58.595 58.595 0 01-5.403-2.125l-.206.456a59.833 59.833 0 01-5.212-2.667l.25-.433a57.943 57.943 0 01-4.881-3.14l-.292.406a55.066 55.066 0 01-4.572-3.658l.333-.373a51.062 51.062 0 01-4.31-4.334l-.375.33a50.417 50.417 0 01-3.972-5.132l.414-.28a47.18 47.18 0 01-3.226-5.551l-.449.22a43.991 43.991 0 01-2.435-6.013l.476-.152a39.21 39.21 0 01-1.458-6.237l-.494.073a35.46 35.46 0 01-.366-6.476l.5.018c.079-2.14.365-4.255.87-6.322l-.486-.118a30.197 30.197 0 012.2-6.096l.45.22a30.06 30.06 0 013.392-5.397l-.393-.31z"
                stroke="#fff"
                strokeDasharray="6 6"
            />
        </svg>
    );
}

export default SvgScrollButtonCircleWhite;
