import React, { useState } from "react";

import type { HeadFC } from "gatsby";
import { FfTwitterPoll } from "../../components/FfTwitterPoll";
import { WithNextButton } from "../../components/WithNextButton";
import { FfMap } from "../../components/FfMap";

import { SEO } from "../../components/Seo";

export const Head: HeadFC = () => <SEO title="Around the world" />;

const FunFact3TriviaPage = () => {
    const [showButton, setShowButton] = useState(false);
    return (
        <main>
            <WithNextButton
                to="/katori"
                type="hyperspace"
                showButton={showButton}
                hyperspaceLineCount={3500}
            >
                <FfMap onComplete={() => setShowButton(true)} />
            </WithNextButton>
        </main>
    );
};

export default FunFact3TriviaPage;

