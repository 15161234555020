import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";
import { Text } from "../Text";

interface IPrevButton {
    onClick?: () => void;
}

export const PrevButton = ({ onClick }: IPrevButton) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({
                    repeat: -1
                })
                .to(".bg", {
                    rotate: -360,
                    ease: "none",
                    duration: 40
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div
            onClick={onClick}
            ref={mainRef}
            css={{
                position: "relative",
                width: pxToRem(60),
                height: pxToRem(60),
                cursor: "pointer"
            }}
        >
            <img
                className="bg"
                css={{
                    width: "100%",
                    // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                }}
                src="/images/prev-button-bg.png"
            />
            <img
                css={{
                    width: "35%",
                    position: "absolute",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    left: "50%",
                    // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                }}
                src="/images/next-button-arrow-left.png"
            />
        </div>
    );
};
