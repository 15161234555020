import React from "react";
import styled from "@emotion/styled";
import {IThemeTypography} from "../../themes";
import * as CSS from "csstype";


interface ITextProps extends Pick<CSS.Properties, "mixBlendMode"> {
    variant: keyof IThemeTypography,
}

export const Text = styled.div<ITextProps>(
    ({theme, variant, color, mixBlendMode}) => {
        return {
            "-webkit-font-smoothing": "antialiased",
            "-moz-osx-font-smoothing": "grayscale",
            ...theme.typography[variant],
            color,
            mixBlendMode
        };
    }
);
