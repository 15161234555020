import React, {useEffect, useRef} from "react";
import {StyledPageLoader} from "./styled";
import {IPageLoader} from "./types";
import {gsap} from "gsap";

export const PageLoader = ({active, children}: IPageLoader & React.PropsWithChildren) => {
    const ref = useRef<HTMLDivElement>(null);
    const childrenRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
        let ctx = gsap.context(() => {
            if (!active) {
                gsap.timeline()
                    .to(ref.current, {
                        autoAlpha: 0, duration: 0.5
                    })
                    .to(childrenRef.current, {
                        autoAlpha: 1,
                        duration: 0.5
                    }, ">-50%")
                gsap.set(ref.current, {zIndex: -2})
            } else {
                gsap.set(ref.current, {zIndex: 100})
            }
        })

        return () => ctx.revert(); // cleanup
    }, [active])
    return (
        <StyledPageLoader>
            <div className="pageloader" ref={ref}/>
            <div ref={childrenRef} className="pageloader-children">
                {children}
            </div>
        </StyledPageLoader>
    )
}
