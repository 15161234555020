import React, { useCallback, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { debounce, throttle } from "lodash";
import { getDistance } from "../../utils/get-distance";

interface IProximityHover extends React.PropsWithChildren {
    onUpdate: (progress: number) => void;
    maxDistance?: number;
    bufferFromCenter?: number;
    disabled?: boolean;
}

export const ProximityHover = ({
    children,
    onUpdate,
    maxDistance = 500,
    bufferFromCenter = 50,
    disabled,
    ...props
}: IProximityHover) => {
    const mainRef = useRef<HTMLDivElement>(null);
    let mainTl = null;

    const throttledOnMouseMove = useCallback(
        throttle((e: MouseEvent) => {
            const el = mainRef.current
                .querySelector<HTMLDivElement>(".proximityTarget")
                .getBoundingClientRect();

            const centerX = el.x + el.width / 2;
            const centerY = el.y + el.height / 2;
            const distance = getDistance(
                e.clientX,
                e.clientY,
                centerX,
                centerY
            );

            // console.log(disabled);

            const effectiveDistance = distance - bufferFromCenter;
            if (effectiveDistance <= maxDistance) {
                const progress = 1 - distance / maxDistance;
                if (onUpdate && progress >= 0) onUpdate(progress);
            }
        }, 25),
        []
    );

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap.timeline({});
            if (!disabled) {
                document.addEventListener(
                    "mousemove",
                    throttledOnMouseMove,
                    false
                );
            } else {
                onUpdate(0);
                document.removeEventListener(
                    "mousemove",
                    throttledOnMouseMove,
                    false
                );
            }
        }, mainRef);

        return () => {
            ctx.revert();
            onUpdate(0);
            document.removeEventListener(
                "mousemove",
                throttledOnMouseMove,
                false
            );
        };
    }, [disabled]);

    return (
        <div
            className="proximity-hover"
            css={{
                pointerEvents: disabled ? "none" : "auto"
            }}
            ref={mainRef}
        >
            <div className="proximityTarget" css={{ display: "inline-block" }}>
                {children}
            </div>
        </div>
    );
};
