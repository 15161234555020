import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ children, selector, onMount }: {
    children: React.ReactNode;
    selector: string;
    onMount?: () => void;
}) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        onMount && onMount();
        return () => setMounted(false);
    }, []);

    return mounted
        ? createPortal(children, document.querySelector(selector))
        : null;
};

export default Portal;
