import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";
import { Text } from "../Text";
import { JV_Z_INDICES } from "../Jamunverse/constants";
import { StaticImage } from "gatsby-plugin-image";

interface IPrevButton {
    onClick?: () => void;
}

export const CancelButton = React.memo(({ onClick }: IPrevButton) => {
    const mainRef = useRef(null);

    let mainTl = null;
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({
                    repeat: -1
                })
                .to(".bg", {
                    rotate: -360,
                    ease: "none",
                    duration: 40
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            onClick={onClick}
            ref={mainRef}
            css={{
                position: "relative",
                width: pxToRem(60),
                height: pxToRem(60),
                cursor: "pointer",
                zIndex: JV_Z_INDICES.activePlanet + 10
            }}
        >
            <StaticImage
                alt=""
                className="bg"
                css={{
                    width: "100%"
                    // filter: `drop-shadow(0px 0px 3px #FF8B38)`
                }}
                src="../../../static/images/next-button-bg.png"
            />
            <div
                css={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Text
                    variant="caption"
                    css={{
                        color: "white"
                    }}
                >
                    x
                </Text>
            </div>
        </div>
    );
});
