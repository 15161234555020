import { IAnyPlanet } from "../components/Jamunverse/types";

export const EncyclopediaContent: {
    [k in IAnyPlanet]: {
        body1: string;
        body2: string;
    };
} = {
    ledikeni: {
        body1: "Ledikeni (লেডিকেনি) or Lady Kenny is rumoured to be prepared for Lady Canning by Bhim Chandra Nag. As we saw before historians disagree on the exact origin story, but no one can dispute the popularity of Ledikeni in the present-day Bengal.",
        body2: "It is a light fried reddish-brown sweet ball made of Chhena and flour, soaked in sugar syrup. "
    },
    "rasgulla-sun": {
        body1: "Rasgulla - syrup balls - is as ancient as some versions of the epic, Ramayana, and is also the topic of epic war between West Bengal, Orissa, and Bangladesh.",
        body2: ""
    },
    gulabjamun: {
        body1: "Gulab Jamun, a rose-scented sweet fritter, either came to India via Turkic invaders; or, via Shah Jehan’s personal chef. While historic sources argue about authenticity, present completely agrees that it is one hell of a ball.",
        body2: "literally meaning ‘Rose water berry’ or ‘Rose berry’, gulab jamun is a staple dessert for majority of religious festivals."
    },
    pantua: {
        body1: "Pantua (পান্তুয়া) is Bengali variation of gulab jamun, often made very dark brown. Also, the second cousin of Ledikeni, first-born of Regent Rasgulla.",
        body2: ""
    },
    kalajam: {
        body1: "Subjected to prolonged sessions of trial-by-fryer, kala jam is almost black in colour. What came at the other side of that process was a delicious huggable ball of sheer sweetness that one can’t stop at just one. We dare you.",
        body2: ""
    },
    jhurra: {
        body1: "The youngest of the family, and also the largest. The Madhya Pradesh-raised jhurre ka rasgulla could weigh 100gm on its own. Quite impressive feat at such a young age.",
        body2: ""
    }
};
