import * as React from "react";

function SvgGjRoadJeepPath(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 1031 440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1027.24 48.998c6.06 18.12-1.83 40.359-22.23 65.315-20.338 24.888-52.822 52.102-95.03 79.975-84.396 55.735-207.374 113.917-348.925 161.297-141.551 47.38-274.774 74.954-375.716 81.257-50.483 3.152-92.804.978-124.028-6.65-31.311-7.649-51-20.657-57.065-38.777-6.065-18.119 1.826-40.358 22.223-65.315 20.34-24.888 52.825-52.101 95.032-79.975 84.397-55.734 207.374-113.916 348.926-161.297C611.978 37.448 745.201 9.874 846.143 3.571c50.483-3.152 92.803-.977 124.028 6.65 31.309 7.65 50.999 20.657 57.069 38.777z"
                stroke="url(#gj-road-jeep-path_svg__paint0_linear_203_89)"
                strokeWidth={3}
            />
            <defs>
                <linearGradient
                    id="gj-road-jeep-path_svg__paint0_linear_203_89"
                    x1={992.465}
                    y1={31.605}
                    x2={68.159}
                    y2={367.501}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0.43} />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default SvgGjRoadJeepPath;
