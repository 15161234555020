import React, { useMemo } from "react";
import { ILetters, IPilowlavaText, PilowlavaText } from "./index";
import { SpaceChar } from "./helpers";

const LETTERS = [
    "/images/letters/j.png",
    "/images/letters/h.png",
    "/images/letters/u.png",
    "/images/letters/r.png",
    "/images/letters/r.png",
    "/images/letters/e.png",
    "space",
    "/images/letters/k.png",
    "/images/letters/a.png",
    "break",
    "/images/letters/r.png",
    "/images/letters/a.png",
    "/images/letters/s.png",
    "/images/letters/g.png",
    "/images/letters/u.png",
    "/images/letters/l.png",
    "/images/letters/l.png",
    "/images/letters/a.png"
];

const WHITE_LETTERS = [
    "/images/letters/white-j.png",
    "/images/letters/white-h.png",
    "/images/letters/white-u.png",
    "/images/letters/white-r.png",
    "/images/letters/white-r.png",
    "/images/letters/white-e.png",
    "space",
    "/images/letters/white-k.png",
    "/images/letters/white-a.png",
    "break",
    "/images/letters/white-r.png",
    "/images/letters/white-a.png",
    "/images/letters/white-s.png",
    "/images/letters/white-g.png",
    "/images/letters/white-u.png",
    "/images/letters/white-l.png",
    "/images/letters/white-l.png",
    "/images/letters/white-a.png"
];

export const JhurreKaRasgullaText = ({
    ...props
}: Omit<IPilowlavaText, "name" | "letters" | "whiteLetters">) => {
    const letters = useMemo<ILetters>(
        () =>
            LETTERS.map((url) => {
                let retVal;
                if (url === "space") {
                    retVal = {
                        el: <SpaceChar />,
                        type: "space"
                    };
                } else if (url === "break") {
                    retVal = {
                        el: <SpaceChar />,
                        type: "break"
                    };
                } else {
                    retVal = {
                        el: <img src={`${url}`} />,
                        type: "letter"
                    };
                }
                return retVal;
            }),
        []
    );

    const whiteLetters = useMemo<ILetters>(
        () =>
            WHITE_LETTERS.map((url) => {
                let retVal;
                if (url === "space") {
                    retVal = {
                        el: <SpaceChar />,
                        type: "space"
                    };
                } else if (url === "break") {
                    retVal = {
                        el: <SpaceChar />,
                        type: "break"
                    };
                } else {
                    retVal = {
                        el: <img src={`${url}`} />,
                        type: "letter"
                    };
                }
                return retVal;
            }),
        []
    );
    return (
        <PilowlavaText
            name="jhurra"
            letters={letters}
            whiteLetters={whiteLetters}
            {...props}
        />
    );
};
