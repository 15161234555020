import * as React from "react";

function SvgFfFuelStation(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 200 531"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M92.38 43.589l11.464-38.96a5 5 0 014.797-3.588h66.257a5 5 0 014.83 3.707l10.395 38.84H92.38z"
                fill="url(#ff-fuel-station_svg__paint0_linear_109_825)"
                stroke="url(#ff-fuel-station_svg__paint1_linear_109_825)"
            />
            <path
                d="M91.621 47c0-8.837 7.163-16 16-16h67.041c8.836 0 16 7.163 16 16v484h-99.04V47z"
                fill="url(#ff-fuel-station_svg__paint2_linear_109_825)"
            />
            <g filter="url(#ff-fuel-station_svg__filter0_d_109_825)">
                <path
                    d="M91.621 444.855V47c0-8.837 7.163-16 16-16h67.041c8.836 0 16 7.163 16 16v397.855"
                    stroke="url(#ff-fuel-station_svg__paint3_linear_109_825)"
                    shapeRendering="crispEdges"
                />
            </g>
            <path
                d="M82.668 124.84l-4.302 2.275v26.339h4.302V124.84z"
                fill="#030303"
            />
            <g filter="url(#ff-fuel-station_svg__filter1_di_109_825)">
                <circle cx={140.738} cy={331.156} r={36.678} fill="#030303" />
                <circle
                    cx={140.738}
                    cy={331.156}
                    r={35.761}
                    stroke="#030303"
                    strokeWidth={1.834}
                />
            </g>
            <g filter="url(#ff-fuel-station_svg__filter2_d_109_825)">
                <circle
                    cx={140.739}
                    cy={331.156}
                    r={31.343}
                    stroke="#FF8B38"
                    strokeWidth={1.5}
                    shapeRendering="crispEdges"
                />
            </g>
            <g filter="url(#ff-fuel-station_svg__filter3_diiii_109_825)">
                <circle cx={140.738} cy={331.157} r={18.339} fill="#FF8B38" />
            </g>
            <path
                d="M76.826 339.156a4 4 0 007.094-3.697l-7.094 3.697zm-61.322-141.23c0-42.903 2.549-65.267 12.347-80.577 9.758-15.247 27.103-24.15 59.693-38.459l-3.216-7.325c-32.044 14.07-51.915 23.814-63.215 41.471-11.26 17.594-13.61 42.39-13.61 84.89h8zm-8 0c0 21.242-1.016 43.633-2.06 65.354-1.043 21.677-2.118 42.722-2.216 61.104-.097 18.303.765 34.36 3.746 45.938 1.49 5.785 3.597 10.821 6.68 14.474 3.209 3.801 7.434 6.034 12.62 6.034v-8c-2.588 0-4.655-1.001-6.506-3.194-1.975-2.34-3.694-6.057-5.047-11.309-2.703-10.498-3.59-25.654-3.493-43.901.097-18.169 1.16-39.012 2.206-60.761 1.044-21.705 2.07-44.278 2.07-65.739h-8zm18.77 192.904c2.614 0 5.017-.639 7.09-2 2.053-1.347 3.534-3.237 4.593-5.351 2.053-4.095 2.764-9.511 2.9-15.26.273-11.525-1.791-26.954-3.105-41.605-1.348-15.025-1.928-29.323.82-39.778 1.356-5.157 3.436-9.024 6.311-11.599 2.802-2.51 6.727-4.124 12.486-4.124v-8c-7.383 0-13.316 2.127-17.823 6.165-4.435 3.972-7.116 9.458-8.71 15.524-3.152 11.992-2.39 27.623-1.052 42.527 1.37 15.279 3.333 29.779 3.075 40.701-.13 5.475-.824 9.412-2.053 11.864-.582 1.159-1.212 1.841-1.83 2.248-.599.392-1.43.688-2.702.688v8zm31.095-119.717c1.695 0 2.895.469 3.87 1.23 1.043.813 2.05 2.14 2.97 4.167 1.888 4.156 2.963 10.204 3.825 17.565.827 7.071 1.466 15.476 2.602 23.174 1.147 7.77 2.885 15.564 6.19 21.907l7.094-3.697c-2.67-5.125-4.257-11.838-5.37-19.378-1.123-7.614-1.68-15.324-2.57-22.935-.857-7.32-2.023-14.521-4.486-19.944-1.254-2.762-2.95-5.307-5.335-7.167-2.453-1.914-5.406-2.922-8.79-2.922v8z"
                fill="#151515"
            />
            <g filter="url(#ff-fuel-station_svg__filter4_df_109_825)">
                <path
                    d="M83.359 333.837a2 2 0 003.547-1.849l-3.547 1.849zm-70.83-135.911c0-42.818 2.182-65.974 12.047-82.037 9.828-16.003 27.481-25.288 59.918-39.53l-1.608-3.662C50.689 86.834 31.76 96.546 21.167 113.796 10.61 130.986 8.53 155.34 8.53 197.926h4zm-4 0c0 21.297-1.012 43.842-2.049 65.721-1.035 21.858-2.095 43.067-2.181 61.611-.086 18.505.794 34.549 3.712 46.015 1.459 5.73 3.47 10.501 6.275 13.873 2.866 3.446 6.555 5.409 11.104 5.409v-4c-3.225 0-5.831-1.325-8.029-3.967-2.26-2.716-4.081-6.833-5.473-12.302-2.783-10.933-3.675-26.533-3.59-45.009.086-18.439 1.141-39.548 2.178-61.441 1.036-21.87 2.053-44.505 2.053-65.91h-4zm16.86 192.629c2.293 0 4.313-.575 6.024-1.73 1.698-1.145 2.968-2.775 3.914-4.697 1.86-3.779 2.602-8.965 2.775-14.824.347-11.732-1.585-27.406-2.812-42.701-1.242-15.478-1.768-30.603 1.162-41.817 1.457-5.576 3.726-10.004 7.022-13.033 3.255-2.991 7.689-4.783 13.837-4.783v-4c-6.995 0-12.444 2.07-16.544 5.837-4.058 3.73-6.614 8.957-8.185 14.967-3.125 11.958-2.516 27.729-1.28 43.148 1.252 15.601 3.14 30.824 2.802 42.264-.17 5.727-.899 10.196-2.366 13.176-.717 1.458-1.575 2.482-2.561 3.147-.973.656-2.192 1.046-3.787 1.046v4zM57.312 266.97c4.133 0 7.1 2.202 9.5 6.386 2.455 4.278 4.128 10.342 5.587 17.391.725 3.5 1.385 7.181 2.067 10.947.68 3.755 1.38 7.588 2.178 11.343 1.591 7.487 3.598 14.818 6.716 20.8l3.547-1.849c-2.858-5.484-4.777-12.379-6.35-19.782-.784-3.689-1.474-7.466-2.155-11.225-.679-3.749-1.349-7.485-2.086-11.045-1.463-7.07-3.234-13.692-6.034-18.57-2.852-4.972-6.947-8.396-12.97-8.396v4z"
                    fill="url(#ff-fuel-station_svg__paint4_linear_109_825)"
                />
                <path
                    d="M83.359 333.837a2 2 0 003.547-1.849l-3.547 1.849zm-70.83-135.911c0-42.818 2.182-65.974 12.047-82.037 9.828-16.003 27.481-25.288 59.918-39.53l-1.608-3.662C50.689 86.834 31.76 96.546 21.167 113.796 10.61 130.986 8.53 155.34 8.53 197.926h4zm-4 0c0 21.297-1.012 43.842-2.049 65.721-1.035 21.858-2.095 43.067-2.181 61.611-.086 18.505.794 34.549 3.712 46.015 1.459 5.73 3.47 10.501 6.275 13.873 2.866 3.446 6.555 5.409 11.104 5.409v-4c-3.225 0-5.831-1.325-8.029-3.967-2.26-2.716-4.081-6.833-5.473-12.302-2.783-10.933-3.675-26.533-3.59-45.009.086-18.439 1.141-39.548 2.178-61.441 1.036-21.87 2.053-44.505 2.053-65.91h-4zm16.86 192.629c2.293 0 4.313-.575 6.024-1.73 1.698-1.145 2.968-2.775 3.914-4.697 1.86-3.779 2.602-8.965 2.775-14.824.347-11.732-1.585-27.406-2.812-42.701-1.242-15.478-1.768-30.603 1.162-41.817 1.457-5.576 3.726-10.004 7.022-13.033 3.255-2.991 7.689-4.783 13.837-4.783v-4c-6.995 0-12.444 2.07-16.544 5.837-4.058 3.73-6.614 8.957-8.185 14.967-3.125 11.958-2.516 27.729-1.28 43.148 1.252 15.601 3.14 30.824 2.802 42.264-.17 5.727-.899 10.196-2.366 13.176-.717 1.458-1.575 2.482-2.561 3.147-.973.656-2.192 1.046-3.787 1.046v4zM57.312 266.97c4.133 0 7.1 2.202 9.5 6.386 2.455 4.278 4.128 10.342 5.587 17.391.725 3.5 1.385 7.181 2.067 10.947.68 3.755 1.38 7.588 2.178 11.343 1.591 7.487 3.598 14.818 6.716 20.8l3.547-1.849c-2.858-5.484-4.777-12.379-6.35-19.782-.784-3.689-1.474-7.466-2.155-11.225-.679-3.749-1.349-7.485-2.086-11.045-1.463-7.07-3.234-13.692-6.034-18.57-2.852-4.972-6.947-8.396-12.97-8.396v4z"
                    fill="url(#ff-fuel-station_svg__paint5_linear_109_825)"
                    fillOpacity={0.2}
                />
            </g>
            <rect
                x={140.738}
                y={84}
                width={6}
                height={16}
                rx={2}
                fill="#1E1E1E"
            />
            <rect
                x={140.738}
                y={108}
                width={6}
                height={16}
                rx={2}
                fill="#1E1E1E"
            />
            <rect
                x={140.738}
                y={132}
                width={6}
                height={16}
                rx={2}
                fill="#1E1E1E"
            />
            <rect
                x={140.738}
                y={156}
                width={6}
                height={16}
                rx={2}
                fill="#1E1E1E"
            />
            <rect
                x={140.738}
                y={180}
                width={6}
                height={16}
                rx={2}
                fill="#1E1E1E"
            />
            <rect
                x={140.738}
                y={204}
                width={6}
                height={16}
                rx={2}
                fill="#1E1E1E"
            />
            <g filter="url(#ff-fuel-station_svg__filter5_d_109_825)">
                <rect
                    x={140.738}
                    y={228}
                    width={6}
                    height={16}
                    rx={2}
                    fill="#fff"
                />
            </g>
            <path
                d="M87.104 326.273c-2.543-5.854-6.627-14.022-9.437-19.524-1.395-2.732-5.182-2.964-6.93-.442a4.104 4.104 0 00-.595 3.387c1.181 4.498 4.172 16.201 5.005 22.498.456 3.455-2.301 18.774-3.922 27.283-.61 3.201 1.418 6.321 4.596 7.039l2.357.533c3.393.768 6.716-1.489 7.18-4.936 1.427-10.562 3.83-31.044 1.746-35.838z"
                fill="#151515"
            />
            <path
                d="M78.547 343.767c-1.155 8.663 5.105 20.748 5.105 20.748s3.226-10.202 3.999-17.681c.844-8.168-.577-21.02-.577-21.02s-7.5 10.259-8.527 17.953z"
                fill="#EE5A2F"
            />
            <path
                d="M69.982 341.125h21.553v68.565H69.982a8 8 0 01-8-8v-52.565a8 8 0 018-8zM78.32 61.872a5 5 0 015-5h8.282v41.08H83.32a5 5 0 01-5-5v-31.08z"
                fill="#131313"
            />
            <defs>
                <linearGradient
                    id="ff-fuel-station_svg__paint0_linear_109_825"
                    x1={141.818}
                    y1={31.71}
                    x2={141.818}
                    y2={-21.636}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0D0E10" />
                    <stop offset={1} stopColor="#0F1113" stopOpacity={0.13} />
                </linearGradient>
                <linearGradient
                    id="ff-fuel-station_svg__paint1_linear_109_825"
                    x1={140.938}
                    y1={50.254}
                    x2={140.938}
                    y2={-21.668}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" />
                    <stop offset={0.627} stopColor="#FF8B38" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ff-fuel-station_svg__paint2_linear_109_825"
                    x1={141.141}
                    y1={31}
                    x2={141.141}
                    y2={531}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#030303" />
                    <stop offset={1} stopColor="#262626" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ff-fuel-station_svg__paint3_linear_109_825"
                    x1={141.141}
                    y1={31}
                    x2={138.027}
                    y2={593.411}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ff-fuel-station_svg__paint4_linear_109_825"
                    x1={92.862}
                    y1={-55.149}
                    x2={126.328}
                    y2={20.411}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EE5A2F" />
                    <stop offset={1} stopColor="#030303" />
                </linearGradient>
                <linearGradient
                    id="ff-fuel-station_svg__paint5_linear_109_825"
                    x1={77.034}
                    y1={197.795}
                    x2={100.253}
                    y2={195.834}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EE5A2F" />
                    <stop offset={1} stopColor="#030303" />
                </linearGradient>
                <filter
                    id="ff-fuel-station_svg__filter0_d_109_825"
                    x={83.121}
                    y={22.5}
                    width={116.041}
                    height={430.355}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={4} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.546587 0 0 0 0 0.220833 0 0 0 0.3 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_109_825"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_109_825"
                        result="shape"
                    />
                </filter>
                <filter
                    id="ff-fuel-station_svg__filter1_di_109_825"
                    x={94.892}
                    y={287.144}
                    width={88.026}
                    height={88.026}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={-1.834} />
                    <feGaussianBlur stdDeviation={3.668} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_109_825"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_109_825"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={5.502} dy={-1.834} />
                    <feGaussianBlur stdDeviation={3.668} />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                    />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                    <feBlend in2="shape" result="effect2_innerShadow_109_825" />
                </filter>
                <filter
                    id="ff-fuel-station_svg__filter2_d_109_825"
                    x={92.646}
                    y={283.063}
                    width={96.186}
                    height={96.186}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius={1}
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_109_825"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.545098 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_109_825"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_109_825"
                        result="shape"
                    />
                </filter>
                <filter
                    id="ff-fuel-station_svg__filter3_diiii_109_825"
                    x={116.4}
                    y={306.148}
                    width={46.345}
                    height={47.015}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={1.834} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.239216 0 0 0 0 0.333333 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_109_825"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_109_825"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={1.834} dy={3.668} />
                    <feGaussianBlur stdDeviation={1.834} />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                    />
                    <feColorMatrix values="0 0 0 0 0.808333 0 0 0 0 0.0134722 0 0 0 0 0.114944 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect2_innerShadow_109_825" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={-6} dy={-6.67} />
                    <feGaussianBlur stdDeviation={3.668} />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                    />
                    <feColorMatrix values="0 0 0 0 0.85 0 0 0 0 0.0283334 0 0 0 0 0.134618 0 0 0 1 0" />
                    <feBlend
                        in2="effect2_innerShadow_109_825"
                        result="effect3_innerShadow_109_825"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={-5.502} dy={-3.668} />
                    <feGaussianBlur stdDeviation={1.834} />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                    />
                    <feColorMatrix values="0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0 0.9875 0 0 0 0.26 0" />
                    <feBlend
                        in2="effect3_innerShadow_109_825"
                        result="effect4_innerShadow_109_825"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={-3} dy={-1} />
                    <feGaussianBlur stdDeviation={1.5} />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                    />
                    <feColorMatrix values="0 0 0 0 0.968627 0 0 0 0 0.486275 0 0 0 0 0.662745 0 0 0 1 0" />
                    <feBlend
                        in2="effect4_innerShadow_109_825"
                        result="effect5_innerShadow_109_825"
                    />
                </filter>
                <filter
                    id="ff-fuel-station_svg__filter4_df_109_825"
                    x={0.293}
                    y={69.697}
                    width={90.84}
                    height={328.858}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={2} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_109_825"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_109_825"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation={1.5}
                        result="effect2_foregroundBlur_109_825"
                    />
                </filter>
                <filter
                    id="ff-fuel-station_svg__filter5_d_109_825"
                    x={138.738}
                    y={226}
                    width={10}
                    height={20}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={1} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_109_825"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_109_825"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default SvgFfFuelStation;
