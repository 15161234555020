import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { FFHomeBackground } from "./Background";
import { useImagesLoaded } from "../../utils/use-images-loaded";

interface IFunFactsHome {
    onPlayButtonClick?: () => void;
}

export const FunFactsHome = ({ onPlayButtonClick }: IFunFactsHome) => {
    const mainRef = useRef(null);

    let mainTl = null;

    return (
        <div ref={mainRef} css={{}}>
            <div className="contents">
                <FFHomeBackground onPlayButtonClick={onPlayButtonClick} />
            </div>
        </div>
    );
};
