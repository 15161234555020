import * as React from "react";

function SvgEnvNavPathInner(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 807 807"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <defs>
                <clipPath id="env-nav-path-inner_svg__b">
                    <use xlinkHref="#env-nav-path-inner_svg__a" />
                </clipPath>
                <path
                    id="env-nav-path-inner_svg__a"
                    d="M.5 403.5a403 403 0 10806 0 403 403 0 10-806 0"
                    stroke="red"
                />
            </defs>
            <use
                xlinkHref="#env-nav-path-inner_svg__a"
                strokeWidth={100}
                stroke="red"
                clipPath="url(#env-nav-path-inner_svg__b)"
            />
        </svg>
    );
}

export default SvgEnvNavPathInner;
