import React, { useLayoutEffect, useState } from "react";
import { gsap } from "gsap";

import type { HeadFC } from "gatsby";
import { WithNextButton } from "../components/WithNextButton";
import { KatoriPage } from "../components/KatoriPage";
import { SEO } from "../components/Seo";

export const Head: HeadFC = () => <SEO title="Katori" />;


const KatoriPagePage = () => {
    const [showButton, setShowButton] = useState(false);

    useLayoutEffect(() => {
        gsap.delayedCall(2, () => setShowButton(true));
    }, [])
    return (
        <main>
            <WithNextButton
                to="/credits"
                type="default"
                showButton={showButton}
            >
                <KatoriPage imageHeight={720} imageWidth={1280} />
            </WithNextButton>
        </main>
    );
};

export default KatoriPagePage;

