import React, { useMemo } from "react";
import { ILetters, IPilowlavaText, PilowlavaText } from "./index";

const LETTERS = [
    "/images/letters/p.png",
    "/images/letters/a.png",
    "/images/letters/n.png",
    "/images/letters/t.png",
    "/images/letters/u.png",
    "/images/letters/a.png"
];

const WHITE_LETTERS = [
    "/images/letters/white-p.png",
    "/images/letters/white-a.png",
    "/images/letters/white-n.png",
    "/images/letters/white-t.png",
    "/images/letters/white-u.png",
    "/images/letters/white-a.png"
];

export const PantuaText = ({
    ...props
}: Omit<IPilowlavaText, "name" | "letters" | "whiteLetters">) => {
    const letters = useMemo<ILetters>(
        () =>
            LETTERS.map((url) => ({
                el: <img src={`${url}`} />,
                type: "letter"
            })),
        []
    );

    const whiteLetters = useMemo<ILetters>(
        () =>
            WHITE_LETTERS.map((url) => ({
                el: <img src={`${url}`} />,
                type: "letter"
            })),
        []
    );
    return (
        <PilowlavaText
            name="pantua"
            letters={letters}
            whiteLetters={whiteLetters}
            {...props}
        />
    );
};
